@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sanchez&display=swap');
*,
:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,
div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,input,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,select,small,span,strike,sub,summary,
sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-smooth: always;
}
ul,
li {
    list-style: none;
}
input,
button {
    font-family: "Nunito Sans";
}
button:focus,
input:focus {
    outline: none;
    box-shadow: none;
}
img {
    max-width: 100%;
}
a,
a:hover {
    text-decoration: none;
}
body {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    font-family: "Nunito Sans";
    background: #161616;
}
.header-wrap {
    background: #282828;
    padding: 13px 0;
}
.main-logo {
    left: -20px;
    position: relative;
}
.main-logo img {
    max-height: 70px;
}
.header-right {
    padding-left: 30px;
}
.main-menu {
    position: relative;
}
.main-menu ul {
    display: flex;
    align-items: center;
}
.main-menu ul li a {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.08px;
    padding: 20px 20px;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out 0s;
}
.main-menu ul li a:hover,
.main-menu ul li a.active,
.main-menu ul li.sub-menu:hover>a {
    color: #79bb30;
    text-decoration: none;
}
.login-block {
    color: #fff;
}
.sub-mega-menu {
    border: 1.2px solid #595A57;
    border-radius: 9px;
    background-color: #2E3033;
    padding: 40px 10px 50px;
    position: absolute;
    right: -80px;
    top: 62px;
    width: 960px;
    display: none !important;
}
.about-m-menu {
    width: 360px;
}
.about-m-menu.sub-mega-menu .smm-col {
    width: 100%;
}
.service-m-menu {
    width: 360px;
    right: 0px;
}
.service-m-menu.sub-mega-menu .smm-col {
    width: 100%;
}

.main-menu ul li:hover .sub-mega-menu {
    display: block !important;
    z-index: 9;
}
.main-menu ul li.sub-menu.open>a {
    color: #619826;
}
.main-menu .inside-menu {
    display: block;
    padding-top: 20px;
}
.inside-menu li {
    width: 100%;
}
.main-menu .inside-menu li {
    padding: 4px 0;
}
.main-menu .inside-menu a {
    padding: 8px 12px;
    display: block;
    font-size: 15px;
    font-weight: 600;
    margin-left: -12px;
}
.main-menu .inside-menu li a:hover,
.main-menu .inside-menu li a.active {
    border-radius: 8px;
    background-color: #212020;
    color: #79bb30;
}
.sub-mega-menu .smm-col {
    width: 30%;
    padding: 20px 50px;
}
.sub-mega-menu .smm-col:nth-child(2) {
    border-left: solid 1px #7E7E7E;
    border-right: solid 1px #7E7E7E;
    width: 40%;
}
.sub-mega-menu .smm-col:nth-child(2) .inside-menu {
    width: 70%;
}
.menu-arrow-icon {
    background: url(../img/white-down-arrow.webp) no-repeat center center;
    background-size: 100%;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.5s ease-in-out 0s;
}

.main-menu li:hover a .menu-arrow-icon,
.main-menu li a.active .menu-arrow-icon,
.main-menu li.sub-menu:hover a .menu-arrow-icon {
    background: url(../img/down-arrow.webp) no-repeat center center;
    background-size: 100%;
}
.mnh-33 {
    min-height: 33px;
}
.green-btn {
    border-radius: 23.5px;
    background-color: #79BB30;
    color: #fff;
    font-family: "Nunito Sans";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding: 10px 25px;
    border: none;
}
.green-btn:hover {
    color: #fff;
}
.transparent-green-btn {
    border-radius: 23.5px;
    background-color: transparent;
    color: #79BB30;
    font-family: "Nunito Sans";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding: 8px 25px;
    border: solid 2px #79BB30;
}
.transparent-green-btn:hover {
    color: #79BB30;
}
.header-wrap .green-btn {
    padding: 11px 40px;
}
.banner-wrap {
    background: #333;
    position: relative;
    padding: 45px 0;
    background-size: cover !important;
    overflow: hidden;
    height: 550px;
}
.banner-pos {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
}
.banner-title {
    font-size: 2.8em;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 1.3em;
    text-align: center;
    color: #fff;
}
.w-240 {
    width: 240px;
}
.white-transparent-btn {
    border-radius: 23.5px;
    color: #fff;
    font-family: "Nunito Sans";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    border: 1px solid #FFFFFF;
    border-radius: 23.5px;
    background-color: #161616;
    padding: 9px 25px;
}
.banner-wrap .green-btn {
    padding: 13px 25px;
    line-height: 19px;
    font-size: 15px;
}
.banner-wrap .white-transparent-btn {
    padding: 12px 25px;
}

.club-community-wrap {
    padding: 70px 0;
    background: #161616;
}
.main-heading {
    font-size: 2.3em;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 1.3em;
    color: #fff;
}
.custom-pills-tabs .nav-pills .nav-link {
    border-radius: 24px;
    background-color: #212020;
    width: 100%;
    border: solid 1px #212020;
    padding: 20px 29px;
    color: #fff;
    font-size: 1.3em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.2em;
    text-align: left;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
}
.custom-pills-tabs .nav-pills li:last-child .nav-link {
    margin-bottom: 0;
}
.custom-pills-tabs .nav-pills .sep-border {
    height: 48px;
    width: 10px;
    opacity: 0.15;
    border-radius: 5px;
    background-color: #FFFFFF;
    display: inline-block;
}
.custom-pills-tabs .nav-pills .nav-link.active {
    border-color: #fff;
    color: #79BB30;
}
.custom-pills-tabs .nav-pills .nav-link.active .sep-border {
    background-color: #79BB30;
    opacity: 1;
}
.custom-pills-tabs .nav-pills .nav-link div {
    padding-left: 22px;
    padding-top: 5px;
}
.custom-pills-tabs .tab-content {
    border-radius: 24px;
    background-color: #212020;
    padding: 40px;
    height: 100%;
}
.md-title {
    color: #FFFFFF;
    font-size: 1.3em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.2em;
}
.custom-pills-tabs .tab-pane p {
    font-size: 1em;
    letter-spacing: 0;
    line-height: 1.5em;
    color: #fff;
}
.custom-pills-tabs .tab-pane p + p {
    margin-top: 1rem !important;
}
.custom-pills-tabs {
    margin: 0 -22px;
}
.custom-pills-tabs .col-md-6 {
    padding: 0 22px;
}

/* Featured Vehicles */
.featured-vehicles-wrap {
    padding: 80px 0 100px;
    background: #161616;
    overflow: hidden;
}
.fv-box {
    border-radius: 24px;
    background-color: #282828;
    overflow: hidden;
}
.fvb-top img {
    width: 100%;
}
.fvb-bottom {
    padding: 16px;
}
.green-btn.sm-btn {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    padding: 7px 24px;
}
.sm-title {
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.2em;
    color: #fff;
}
.xs-title {
    font-size: 0.9em;
    letter-spacing: 0;
    line-height: 1.1em;
    color: #fff;
}
.w-330 {
    width: 330px;
}
.mobile-app-wrap {
    background-color: #212020;
    padding: 60px 0;
    overflow: hidden;
}
.fw-400 {
    font-weight: 400 !important;
}
.mobile-app-form .green-btn {
    border-radius: 15px;
    padding: 18px 20px;
    width: 150px;
    text-align: center;
    font-size: 1.3em;
}
.mobile-app-form .form-control {
    font-size: 1.1em;
    letter-spacing: 0;
    line-height: 21px;
    color: #000;
    border: none;
    border-radius: 15px;
    background-color: #FFFFFF;
    padding: 17px 22px;
    width: calc(100% - 170px);
}
.me-20 {
    margin-right: 20px !important;
}
.membership-perks-wrap {
    background: #161616;
    padding: 70px 0;
    overflow: hidden;
}
.mp-box {
    height: 180px;
    width: 180px;
    border-radius: 24px;
    background-color: #212020;
}
.text-green {
    color: #79BB30 !important;
}
.mp-title {
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
}
.become-member-wrap {
    height: 550px;
    background-size: cover !important;
    position: relative;
    overflow: hidden;
}
.become-member-pos {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
}
.fw-700 {
    font-weight: 700 !important;
}
.green-btn.md-btn {
    padding: 14px 25px;
}
.footer-wrap {
    background-color: #212020; 
    padding: 55px 0 55px;
}
.footer-wrap p {
    font-size: 1.1em;
    letter-spacing: 0;
    line-height: 24px;
    color: #fff;
}
.footer-menu li a {
    font-size: 1em;
    letter-spacing: 0;
    line-height: 19px;
    color: #fff;
    font-weight: 400;
    display: block;
    padding: 7px 0;
}

.vdp-md-title {
    color: #FFFFFF;
    font-size: 1.5em;
    font-weight: 900;
    letter-spacing: -0.56px;
    line-height: 28px;
}
.lg-btn {
    font-size: 1.4em !important;
    line-height: normal;
    border-radius: 50px;
    padding: 13px 65px;
    font-weight: 900;
}
.mxw-1366 {
    max-width: 1366px;
    padding-left: 30px;
    padding-right: 30px;
}
.custom-breadcrumb .breadcrumb-item {
    color: #fff;
    font-size: 1em;
    letter-spacing: -0.36px;
    line-height: 1.3em;
}
.custom-breadcrumb .breadcrumb-item+.breadcrumb-item {
    padding-left: .8rem;
}
.custom-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    --bs-breadcrumb-divider: '>';
    color: #fff;
    padding-right: .8rem;
}
.custom-breadcrumb .breadcrumb-item a {
    color: #79BB30;
}
.vdp-gallery-wrap {
    padding: 50px 0 99px;
}
.vdp-gallery-tab .nav-tabs {
    justify-content: center;
    border: none;
}
.vdp-gallery-tab .nav-tabs li {
    margin: 0 15px;
}
.vdp-gallery-tab .nav-tabs .nav-link {
    color: #79BB30;
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: -0.52px;
    line-height: 1.3em;
    text-align: center;
    padding: 10px 35px;
    border: none;
}
.vdp-gallery-tab .nav-tabs .nav-link.active {
    border-radius: 28px;
    background-color: #FFFFFF;
    color: #04121D;
}
.mb-25 {
    margin-bottom: 25px !important;
}
.gallery-box img {
    width: 100%;
    border-radius: 6px;
}
.common-arrow.owl-carousel .owl-nav button.owl-next,
.common-arrow.owl-carousel .owl-nav button.owl-prev {
    width: 65px;
    height: 65px;
    position: absolute;
    top: 50%;
    margin-top: -33px;
    text-indent: -99999px;
}
.common-arrow.owl-carousel .owl-nav button.owl-next {
    background: url(../img/arrow-right.webp) no-repeat center center;
    background-size: 100%;
    right: -32px;
}
.common-arrow.owl-carousel .owl-nav button.owl-prev {
    background: url(../img/arrow-right.webp) no-repeat center center;
    background-size: 100%;
    left: -32px;
    transform: rotate(180deg);
}
.common-arrow.owl-carousel .owl-nav button.owl-next.disabled,
.common-arrow.owl-carousel .owl-nav button.owl-prev.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.mt-40 {
    margin-top: 40px !important;
}
.vdpa-box {
    border: 1px solid #545651;
    background-color: rgba(40,40,40,0.8);
    padding: 40px;
    border-radius: 15px;
}
.vdpa-box p {
    color: #fff;
    font-size: 1.1em;
    letter-spacing: -0.63px;
    line-height: 1.6em;
}
.vdpa-box p a {
    color: #79BB30;
    text-decoration: none;
}
.cs-label {
    color: #FFFFFF;
    font-size: 13px;
    letter-spacing: -0.09px;
    line-height: 15px;
    font-weight: 300;
}
.cs-require {
    color: #898484;
    font-size: 13px;
    letter-spacing: -0.09px;
    line-height: 15px;
    text-align: right;
  
}
.custom-form .row {
    margin: 0 -40px;
}
.custom-form .col-md-6 {
    padding: 0 40px;
}
.custom-form .form-group {
    margin-bottom: 30px;
}
.h-218 {
    height: 218px !important;
}
.phone-icon {
    background: url(../img/local_phone.svg) no-repeat center center;
    background-size: 100%;
    height: 18px;
    width: 18px;
    display: inline-block;
}
.w-300 {
    width: 300px;
}
.vdpa-table tr th,
.vdpa-table tr td {
    border-bottom: solid 2px rgb(255 255 255 / 30%);
    padding: 20px 6px;
}
.vdpa-table tr th {
    opacity: 0.5;
    color: #FFFFFF;
    font-size: 1em;
    letter-spacing: -0.34px;
    line-height: 1.2em;
    font-weight: 400;
}
.vdpa-table tr td {
    color: #FFFFFF;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: -0.4px;
    line-height: 1.3em;
    text-align: right;
}
.trans-btn {
    border: none;
    background: transparent;
    font-size: 1.1em;
    letter-spacing: -0.4px;
    line-height: 1.3em;
    font-weight: 500;
}
.doc_icon {
    background: url(../img/receipt-icon.svg) no-repeat center center;
    background-size: 100%;
    width: 15px;
    height: 17px;
    display: inline-block;
    margin-right: 7px;
    vertical-align: -2px;
}
.copy_icon {
    background: url(../img/copy-icon.svg) no-repeat center center;
    background-size: 100%;
    width: 18px;
    height: 21px;
    display: inline-block;
    margin-right: 7px;
    vertical-align: -4px;
}
.vdp-gallery-wrap {
    background: url(../img/bottom-bg.svg) no-repeat center bottom;
    overflow: hidden;
}
.fw-700 {
    font-weight: 700 !important;
}
.srp-wrap {
    padding: 40px 0 110px;
    overflow: hidden;
}
.search-box {
    position: relative;
    border-radius: 24px;
    background-color: #FFFFFF;
    width: 100%;
}
.search-icon {
    background: #79BB30 url(../img/search-icon.svg) no-repeat center center;
    border-radius: 0 24px 24px 0;
    background-size: 28px 28px;
    border: none;
    width: 80px;
    height: 48px;
}
.search-box .form-control {
    background: transparent;
    border: none;
    height: 48px;
    color: #000000;
    font-size: 1.1em;
    letter-spacing: 0;
    line-height: 21px;
    padding: 10px 26px;
}
.search-box .form-control:focus {
    box-shadow: none;
}
.srp-filter-btn {
    border: 2px solid #FFFFFF;
    border-radius: 35px;
    background-color: #282828;
    width: 152px;
    color: #fff;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    padding: 12px 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.srp-filter-btn img {
    margin-right: 10px;
    display: block;
}
.pro-box {
    transition: all 0.5s ease-in-out 0s;
    position: relative;
    width: 100%;
    min-height: 350px;
    border-radius: 30px;
    perspective: 500px;
    z-index: 0;
}
.pro-box:hover {
    z-index: 11;
}
.pro-box .pro-content {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    transition: transform 1s;
    transform-style: preserve-3d;
    z-index: 0;
}
.pro-box:hover .pro-content {
    transform: rotateY(180deg);
    transition: transform 1.2s;
    z-index: 11;
    border-radius: 30px;
}
.pro-box .before-hover {
    border-radius: 30px;
    background-color: #282828;
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
}
.pro-box .after-hover {
    background-color: #282828;
    box-shadow: 0 16px 30px 0 #000000;
    border: 1px solid #838181;
    width: 465px;
    height: 510px;
    border-radius: 30px;
    backface-visibility: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -233px;
    margin-top: -255px;
}
.pro-box .after-hover {   
  transform: rotateY( 180deg );
}
.pro-top {
    border-radius: 30px 30px 0 0;
    overflow: hidden;
}
.pro-top img {
    height: 208px;
    object-fit: cover;
    width: 100%;
    border-radius: 30px 30px 0 0;
}
.pro-bottom {
    padding: 24px;
}

.font-1-1em {
    font-size: 1.1em !important;
}
.font-1em {
    font-size: 1em !important;
}
.pro-bottom .green-btn {
    padding: 8px 46px;
    font-size: 1.1em;
}
.info-icon {
    border: none;
    background: transparent;
}
.product-list .row {
    margin-left: -20px;
    margin-right: -20px;
}
.product-list .col-md-4,
.product-list .col-md-8 {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
    position: relative;
    z-index: 0;
}
.product-list .col-md-4:hover,
.product-list .col-md-8:hover {
    z-index: 9;
}
.pro-full-width .before-hover {
    padding: 25px;
    border: 2px solid #79BB30;
}
.pro-full-width .pro-top {
    width: 45%;
}
.pro-full-width .pro-top {
    width: 45%;
}
.pro-full-width .after-hover {
    padding: 25px;
}
.pro-full-width .pro-top img {
    height: 284px;
    border-radius: 30px;
}
.pro-full-width .after-hover {
    width: 100%;
    margin-left: -50%;
    height: auto;
    margin-top: -165px;
}
.pro-full-width .pro-bottom {
    width: 65%;
}
.pro-full-width .after-hover .green-btn {
    padding: 8px 30px;
}
.lt-main-title {
    color: #79BB30;
    font-size: 2em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.2em;
}
.pro-full-width .sm-title {
    font-size: 1.8em;
}
.pro-full-width .green-btn {
    padding: 14px 30px;
    font-size: 1.1em !important;
    line-height: normal;
    border-radius: 50px;
}
.pro-full-width .xs-title {
    font-size: 1.2em;
}
.srplist-slider .owl-dots {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    bottom: 7px;
}
.srplist-slider button.owl-dot {
    height: 8px;
    width: 8px;
    opacity: 0.5;
    background-color: #FFFFFF;
    margin: 0 5px;
    border-radius: 50%;
}
.srplist-slider button.owl-dot.active {
    opacity: 1;
}
.srplist-slider.owl-carousel .owl-nav button.owl-next, 
.srplist-slider.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 30px;
    height: 30px;
}
.srplist-slider.owl-carousel .owl-nav button.owl-prev {
    background: url(../img/arrow-left.svg) no-repeat center center;
    background-size: 100%;
    left: 15px;
}
.srplist-slider.owl-carousel .owl-nav button.owl-next {
    background: url(../img/arrow-right.svg) no-repeat center center;
    background-size: 100%;
    right: 15px;
}

.srplist-slider.owl-carousel .owl-nav button.owl-next span, 
.srplist-slider.owl-carousel .owl-nav button.owl-prev span {
    display: none;
}
.after-hover .pro-bottom p {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    color: #fff;
}
.custom-hr {
    background-color: #7E7E7E;
}
.view-sticker-btn {
    border: none;
    background: transparent;
    font-size: 0.9em;
    letter-spacing: -0.32px;
    line-height: 19px;
    font-weight: 400;
    padding: 0;
    text-align: left;
}
.w-60 {
    width: 60% !important;
}
.w-40 {
    width: 40% !important;
}
.w-30 {
    width: 24% !important;
}
.w-70 {
    width: 76% !important;
}
.after-hover .pro-bottom .xs-title {
    font-size: 13px;
}
.after-hover .pro-bottom .sm-title {
    font-size: 15px;
}
.srp-filter-block {
    position: relative;
    top: 0;
    left: 0;
}
.product-block.sticky .srp-filter-block {
    position: fixed;
    width: 100%;
    background: #282828;
    padding: 22px 56px;
    border-bottom: solid 1px rgb(255 255 255 / 40%);
    z-index: 99;
    box-shadow: 0 8px 8px 0 rgba(0,0,0,0.75);
}
.banner-box {
    border-radius: 60px;
    background-color: #282828;
    padding: 0 45px;
}
.banner-box > .d-flex {
    height: 120px;
}
.banner-box + .banner-box {
    margin-top: 25px;
}
.bb-title {
    color: #79BB30;
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.2em;
}
.bb-content {
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
}
.bb-icon {
    min-width: 48px;
}
.w-55 {
    width: 55% !important;
}
.w-45 {
    width: 45% !important;
}
.mt-44 {
    margin-top: 44px !important;
}
.featured-vehicles-list {
    margin-left: -22px;
    margin-right: -22px;
}
.featured-vehicles-list .col-md-6 {
    padding-left: 22px;
    padding-right: 22px;
}
.featured-vehicles-list .fv-box {
    border-radius: 24px;
}
.featured-vehicles-list .fvb-bottom {
    padding: 30px 40px 50px;
}
.featured-vehicles-list .fvb-bottom p {
    min-height: 48px;
}
.fvb-list {
    margin-top: 30px;
    min-height: 300px;
}
.fvb-list li {
    font-size: 1em;
    color: #fff;
    margin: 0 0 30px;
    position: relative;
    padding-left: 27px;
}
.fvb-list li:before {
    content: '';
    height: 12px;
    width: 12px;
    background-color: #79BB30;
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 50%;
}
.mp-icon {
    min-height: 56px;
}
.font-2-8em {
    font-size: 2.8em !important;
}
.service-banner-wrap {
    height: 500px;
    overflow: hidden;
}
.service-banner {
    height: 420px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
}
.service-banner-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: right;
    z-index: 0;
}
.service-md-title {
    color: #79BB30;
    font-size: 1.7em;
    letter-spacing: -0.56px;
    line-height: 1.5em;
}
.sf-img img {
    height: 580px;
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
}
.sf-box {
    border: 1px solid #545651;
    background-color: rgba(40,40,40,0.6);
    padding: 60px 80px;
    border-radius: 25px;
    overflow: hidden;
}
.sfb-icon {
    min-width: 29px;
}
.sfb-title {
    color: #FFFFFF;
    font-size: 1.9em;
    font-weight: bold;
    letter-spacing: -0.6px;
    line-height: 1.2em;
}
.sfb-content p {
    color: #FFFFFF;
    font-size: 1.1em;
    letter-spacing: -0.36px;
    line-height: 1.2em;
}
.sf-box li + li {
    margin-top: 50px;
}
.service-featured-wrap {
    padding: 30px 0 70px;
    overflow: hidden;
}
.service-featured-wrap .row {
    margin-left: -30px;
    margin-right: -30px;
}
.service-featured-wrap .col-md-6 {
    padding-left: 30px;
    padding-right: 30px;
}
.profit-margins-wrap {
    padding: 0 0 50px;
}
.pm-title {
    position: relative;
}
.pm-title {
    color: #FFFFFF;
    font-family: "Sanchez", serif;
    font-size: 2.8em;
    letter-spacing: -1.59px;
    line-height: 1.3em;
    text-align: center;
    position: relative;
}
.profit-margins-wrap p {
    color: #FFFFFF;
    font-size: 1.2em;
    letter-spacing: -0.63px;
    line-height: 1.8em;
    max-width: 1090px;
    margin: 0 auto;
}
.mxw-1230 {
    max-width: 1230px;
}
.pm-logos img {
    max-width: 175px;
    max-height: 93px;
}
.mt-28 {
    margin-top: 28px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.font-1-9em {
    font-size: 1.9em !important;
}
.reliable-wrap {
    padding: 70px 0 110px;
}
.reliable-title {
    color: #79BB30;
    font-size: 2.2em;
    letter-spacing: 0.18px;
    line-height: 1.5em;
    text-align: center;
    font-weight: 900;
}
.reliable-value {
    color: #FFFFFF;
    font-size: 4em;
    font-weight: bold;
    letter-spacing: -1.41px;
    line-height: 1em;
    text-align: center;
}
.reliable-label {
    color: #79BB30;
    font-size: 1.2em;
    letter-spacing: 0.11px;
    line-height: 1.2em;
    text-align: center;
}
.reliable-block .col-md-3 +  .col-md-3 {
    border-left: solid 1px rgb(197 199 202 / 30%);
}
.map-wrap {
    padding: 80px 0;
}
.contact-wrap {
    padding: 0 0 80px;
}
.gray-box {
    border: 1px solid #545651;
    background-color: rgba(40, 40, 40, 0.6);
    padding: 60px 80px;
    border-radius: 25px;
    overflow: hidden;
}
.service-from .cs-label {
    font-size: 14px;
    margin-bottom: 11px;
}
.service-from .form-group {
    margin-bottom: 22px;
}
.service-from .form-control {
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #333;
    font-family: "Nunito Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    height: 48px;
}
.service-from .form-control:focus {
    box-shadow: none;
}
.service-from select.form-control {
    background: #fff url(../img/gray-down-arrow.svg) no-repeat calc(100% - 10px)
        center;
    background-size: 15px 9px;
}
.service-from textarea.form-control {
    height: 350px;
    resize: none;
    padding: 15px;
}
.mx-50 {
    margin-left: -50px !important;
    margin-right: -50px !important;
}
.px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
}
.react-datepicker-wrapper {
    width: 100%;
}
.service-from .form-control.calendar-field {
    background: #fff url(../img/date_range_icon.svg) no-repeat calc(100% - 10px) center;
    background-size: 18px 20px;
}
.service-from .form-control.time-field {
    background: #fff url(../img/time_icon.svg) no-repeat calc(100% - 10px) center;
    background-size: 20px 20px;
}

.map-box iframe {
    display: block;
    border-radius: 25px;
}
.footer-logo img {
    max-height: 58px;
    margin-left: -24px;
}

.vdp-main {
    overflow: hidden;
}
.counter:not(.is-visible) {
	visibility: hidden;
}

/* About Page */
.about-wrap {
    padding: 110px 0;
}
.cs-content p {
    font-size: 1.4em;
}
.cs-content p + p {
    margin-top: 25px;
}
.col-pro {
    opacity: 0;
}
.meet-team-box {
    border-radius: 9px;
    background-color: #2E3033;
    padding: 20px 30px 35px;
}
.border-l {
    border-left: solid 1px #fff;
}
.auction-tabs .nav-tabs {
    border-bottom: none;
}
.auction-tabs .nav-tabs > li {
    margin-right: 4px;
}
.auction-tabs .nav-tabs > li > button {
    background-color: #5c5d5e;
    border: none;
    color: #ffffff;
    text-shadow: 0px 0px 1px #666;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
}

.auction-tabs .nav-tabs > li > button.active, 
.auction-tabs .nav-tabs > li > button.active:focus, 
.auction-tabs .nav-tabs > li > button.active:hover {
    background-color: #343638;
    color: White;
    border: none;
}
.auction-tabs .tab-content {
    padding: 20px;
    border-radius: 5px;
    background-color: #343638;
    color: White;
    overflow: hidden;
}

.auction-table tr td {
    color: #fff;
    padding: 10px 0;
}
.auction-table tr.option-answer-tr td{
    color: #939394;
    font-size: 14px;
}
.red {
    color: #bd1429 !important;
}
.seller-info p {
    font-size: 0.9em;
    font-weight: 300;
}
.seller-circle-box {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: #cccccc;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50%;
    width: 90px;
    height: 90px;
}
.seller-graphs .title {
    color: #cccccc;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    text-align: center;
    line-height: 19px;
}
.seller-graphs .value-par {
    text-align: center;
    padding-top: 26px;
    z-index: 2;
    position: absolute;
    color: #ffffff;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: -0.53px;
    line-height: 35px;
    text-align: center;
    width: 100%;
}
.tooltip-btn {
    position: relative;
}
.tooltip-btn .tooltip-hover {
    display: none;
    background: #fff;
    box-shadow: 0 0 3px rgba(200, 200, 200, 0.5);
    padding: 8px;
    border-radius: 3px;
    font-size: 11px;
    color: #8a8383;
    position: absolute;
    width: 280px;
    z-index: 9;
    left: 50%;
    margin-left: -140px;
    bottom: 20px;
    line-height: 14px;
}
.seller-circle-box:after {
    position: absolute;
    display: block;
    top: 5px;
    left: 5px;
    width: 80px;
    height: 80px;
    content: '';
    background-color: #343638;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
}
.tooltip-btn:hover .tooltip-hover {
    display: block;
}
.tooltip-btn .tooltip-hover::after {
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -7px;
    display: inline-block;
    border-left: 7px solid transparent !important;
    border-top: 9px solid #fff !important;
    border-right: 7px solid transparent;
    content: '';
}
.info-icon {
    background: url(../img/info.webp) no-repeat center center;
    background-size: 100%;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: -4px;
}
.bid-history-scroll {
    max-height: 438px;
    overflow-y: auto;
    padding-right: 10px;
}
.bid-history-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}
.bid-history-scroll::-webkit-scrollbar-track {
    background-color: #ccc;
    border-radius: 3px;
}
.bid-history-scroll::-webkit-scrollbar-thumb {
    background-color: #545651;
    border-radius: 3px;
}
.mnw-220 {
    min-width: 220px;
}
.transparent-btn {
    background: transparent;
    color: #fff;
    font-family: "Nunito Sans";
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding: 8px 25px;
    border: none;
}
.ask-question-control {
    resize: none;
}
.mmr-maheim-price {
    display: flex;
    margin: 0px auto 20px;
    position: relative;
    height: 166px;
    overflow: hidden;
}
.mmr-maheim-price__line {
    position: absolute;
    height: 1px;
    width: 80%;
    border-bottom: 1px dashed #ffffff;
    right: 0;
    top: 31px;
}
.mmr-maheim-price__price {
    flex: 0 0 40%;
    margin-top: auto;
    margin-right: 5%;
    /* height: 166px; */
    background: linear-gradient(0deg, #7fc247 0%, #619826 100%);
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: -0.18px;
    line-height: 34px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mmr-maheim-price__our-price {
    flex: 0 0 65%;
    margin-top: auto;
    flex-basis: 55%;
    /* height: 135px; */
    background: linear-gradient(180deg, #b62913 0%, #d4381f 100%);
    background-color: #d4381f;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.09px;
    line-height: 19px;
    text-align: center;
    padding-top: 16px;
}
.mmr-maheim-price__our-price .price-tl {
    position: absolute;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.08px;
    line-height: 18px;
    text-align: center;
    top: -45px;
    left: 0;
    width: 100%;
}
.mmr-maheim-price__our-price span {
    position: absolute;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.08px;
    line-height: 18px;
    text-align: center;
    top: 5px;
}
.auction-content .vdpa-box p {
    font-size: 0.9em;
}

.text-overlay {
    position: absolute;
    line-height: 40px;
    color: #ffffff;
    background: linear-gradient(180deg, #636161 10%, #4d4c4c 100%);
    z-index: 2;
    right: 0;
    top: 0;
    box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.text-overlay-numbers {
    font-size: 14px;
    font-weight: 900;
    letter-spacing: -0.11px;
    padding: 0 10px;
}
.text-overlay-text {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.09px;
    text-align: right;
    padding: 0 19px 0 10px;
    line-height: 16px;
}
.btn-overlay {
    position: absolute;
    color: #ffffff;
    z-index: 2;
    left: 10px;
    bottom: 10px;
    transform: translateY(-100%);
}
.btn-overlay button {
    min-height: 34px;
    min-width: 180px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.09px;
    line-height: 16px;
    outline: none;
    border-style: hidden;
}
.white-bg {
    background: #fff;
}
.mt-circle {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    min-width: 90px;
}
.mnh-340 {
    min-height: 280px;
}

.drop-line-wrap {
    padding: 60px 0;
    overflow: hidden;
    background-size: cover !important;
    background-attachment: fixed !important;
}
.font-1-3em {
    font-size: 1.3em !important;
}
.mxw-980 {
    max-width: 980px;
}

.register-to-bid-block {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 90px;
    color: White;
    background-color: #212020;
    box-shadow: 0 -5px 8px 0 rgba(0,0,0,0.2);
    z-index: 4;
    padding-top: 13px;
}
.register-to-bid-block__info .tooltip-btn .tooltip-hover {
    left: -40px;
    margin-left: 0;
}
.register-to-bid-block__info .tooltip-btn .tooltip-hover::after {
    left: 17.5%;
}

.arrow-animation {
    position: relative;
    animation: pulse 3s ease-out;
    animation-iteration-count: infinite;
    top: 0;
}
@keyframes pulse {
    0% {
    opacity: 0.5;
    top: 0px;
    }
    50% {
    opacity: 1;
    top: 10px;
    }
    100% {
    opacity: 0.5;
    top: 0px;
    }
}
  .arrow-icon {
    background-image: url(../img/arrow-down.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    width: 32px;
    height: 32px;
    display: block;
    margin: 0 auto;
  }

.blob {
	background: #fff;
	border-radius: 50%;
	height: 10px;
	width: 10px;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	transform: scale(1);
	animation: blobPulse 2s infinite;
    display: block;
    position: absolute;
    top: -4px;
    left: -5px;
    border-radius: 50%;
}
.blob > span {
	background: #fff;
	border-radius: 50%;
    height: 10px;
    width: 10px;
    background-color: rgba(255, 255, 255, 1);
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
    border-radius: 50%;
}
@keyframes blobPulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}
.bar-graph .year {
    -webkit-animation: fade-in-text 2.2s 0.1s forwards;
    -moz-animation: fade-in-text 2.2s 0.1s forwards;
    animation: fade-in-text 2.2s 0.1s forwards;
}

.bar-graph-vertical {
    margin-top: 80px;
    max-width: 680px;
}

.bar-graph-vertical .bar-container {
    float: left;
    height: 150px;
    margin-right: 8px;
    position: relative;
    text-align: center;
    width: 40px;
}

.bar-graph-vertical .bar {
    border-radius: 3px;
    bottom: 40px;
    position: absolute;
    width: 40px;
}

.bar-graph-vertical .year {
    bottom: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.bar {
    -webkit-animation: show-bar-one-vertical 1.2s 0.4s forwards;
    -moz-animation: show-bar-one-vertical 1.2s 0.4s forwards;
    animation: show-bar-one-vertical 1.2s 0.4s forwards;
}
@keyframes show-bar-one-vertical {
    0% {
      height: 0px;
    }
    100% {
      height: 166px;
    }
}
.bar2 {
    -webkit-animation: show-bar-two-vertical 1.2s 0.4s forwards;
    -moz-animation: show-bar-two-vertical 1.2s 0.4s forwards;
    animation: show-bar-two-vertical 1.2s 0.4s forwards;
}
@keyframes show-bar-two-vertical {
    0% {
      height: 0px;
    }
    100% {
      height: 135px;
    }
}
.vdpa-about-text p {
    margin-bottom: 20px;
}
.h-247 {
    height: 247px !important;
}
.f-thumbs.is-horizontal .f-thumbs__track {
    transform: inherit !important;
    margin: 0 auto;
}

/* Modal */
.custom-modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
}
.overlay-modal {
    background: rgb(0 0 0 / 50%);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
}
.grand-opening-modal .overlay-modal {
    background: rgb(0 0 0 / 80%);
}
.custom-modal .modal-dialog {
    z-index: 101;
}
.custom-modal .modal-header {
    border-bottom: 1px solid #cccccc;
    padding: 0 0 12px;
}
.custom-modal .modal-title {
    color: #4c5258;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: -0.11px;
    line-height: 20px;
}
.filter-modal-title {
    text-transform: capitalize;
}
.custom-modal .modal-content {
    padding: 24px;
    border-radius: .3rem;
}
.fade:not(.show) {
    display: none;
}
.register-content {
    color: #666666;
    border: 1px solid #376F96;
    border-radius: 0;
    min-height: auto;
    background: url(../img/bg-img-apply-now.webp) no-repeat 0 0;
    background-size: cover;
}
.sm-box-close {
    color: #9b9b9b;
    background: transparent;
    border: none;
    float: right;
    font-size: 20px;
    margin-top: -5px;
    padding-right: 0;
}
.text-dgray {
    color: #929292 !important;
}
.register-title {
    font-size: 40px;
    color: #666666;
    text-align: center;
    font-weight: 900;
    margin: 20px 0 50px;
}
.register-wrapper {
    padding: 0 55px 25px;
}
.register-left {
    padding: 0 70px 0 0;
}
.register-right {
    border-left: 1px solid #999999;
    padding: 0 0 0 70px;
}
.register-form-title {
    text-align: center;
    color: #666666;
    font-size: 30px;
}
.register-about {
    font-size: 18px;
    color: #666666;
    text-align: center;
}
.register-btn .btn {
    height: 60px;
    width: 100%;
    background-color: #cd290e;
    background: linear-gradient(180deg, #dc3317 0%, #c12208 100%);
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-top: 101px;
    letter-spacing: -0.14px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;
}
.form-group label {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 4px;
}
.form-group input {
    border-radius: 0;
    min-height: 40px;
}
.forgot-link {
    font-size: 12px;
    text-align: center;
    margin: 30px 0 0;
    font-weight: 900;
    cursor: pointer;
}
.or {
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    width: 30px;
    background-color: #666666;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.08px;
    line-height: 16px;
    text-align: center;
    padding-top: 8px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: -16px;
    z-index: 1;
}
.mt-114 {
    margin-top: 114px !important;
}
.modal-dialog.mxw-680 {
    max-width: 680px !important;
}

/* .custom-checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  .custom-checkbox label {
    position: relative;
    cursor: pointer;
    padding-left: 27px;
  }
  .custom-checkbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: #fff;
    border: none;
    padding: 8px 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 2px;
    border: solid 1px #CCCCCC;
    vertical-align: -3px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .custom-checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 5px;
    height: 11px;
    border: solid #4267b2;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  } */
  
.custom-modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.fancybox__content {
    width: 100% !important;
    height: 70vh !important;
}
.overflow-body {
    overflow: hidden;
}
.cc-icons {
    background: url(../img/cc-icons.webp) no-repeat transparent 0 0;
    min-height: 40px;
    margin-top: 10px;
}
.norton-badge {
    background: url(../img/norton-badge.webp) no-repeat transparent 0 0;
    background-size: contain;
    max-height: 38px;
    margin-top: 0;
    min-height: 38px;
}

.custom-radio [type="radio"]:checked,
.custom-radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.custom-radio [type="radio"]:checked + label,
.custom-radio [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.custom-radio [type="radio"]:checked + label:before,
.custom-radio [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.custom-radio [type="radio"]:checked + label:after,
.custom-radio [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #cd290e;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.custom-radio [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.custom-radio [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.region-tag {
    height: 40px;
    font-size: 14px;
    padding: 10px;
    text-align: left;
    color: #ffffff;
    font-weight: 400;
    background-color: #cccccc;
    color: #666666;
    border-radius: 5px;
    width: 190px;
}
.h-120 {
    height: 120px !important;
}
.fw-900 {
    font-weight: 900 !important;
}
.attact-photo-btn {
    cursor: pointer;
}
.attact-photo-btn input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
}
.mob-bb-content {
    display: none;
}
.sf-tag {
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 13px;
    padding: 6px 13px 5px;
    display: inline-block;
    border-radius: 14px;
    background-color: #7E7E7E;
}
.sf-tag img {
    margin-left: 5px;
    vertical-align: -2px;
}
.vdp-banner-flex {
    margin-top: -50px;
}
/* Contact */
.contact-banner {
    padding: 105px 0 110px;
}
.contact-info-box {
    padding: 30px 40px;
}
.contact-info-box p {
    line-height: 30px;
}
.contact-flex {
    margin-left: -55px;
    margin-right: -55px;
}
.contact-flex .col-md-6 {
    padding-left: 55px;
    padding-right: 55px;
}

.ferrari-service-banner {
    padding: 50px 0 70px;
}
.btns-group {
    padding: 60px 0;
}
.service-info-table tr th,
.service-info-table tr td {
    border: none;
    padding: 8px 0;
}
.service-info-table .md-title {
    padding: 15px 0;
    border-top: 2px solid #545651;
    border-bottom: 2px solid #545651;
}
.content p {
    font-size: 1.2em;
    letter-spacing: -0.63px;
    line-height: 36px;
    color: #fff;
}
.content p + p {
    margin-top: 30px;
}
.font-2em {
    font-size: 2em !important;
}
.vintage-table tr th {
    color: #79BB30;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: -0.36px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    padding: 18px 30px;
    width: 25%;
}
.vintage-table tr td {
    color: rgb(255 255 255 / 51%);
    font-size: 1.05em;
    letter-spacing: -0.34px;
    text-align: center;
    padding: 18px 30px;
    width: 25%;
}
.vintage-table tr th:first-child {
    text-align: left;
}
.vintage-table tr td:first-child {
    text-align: left;
    color: #FFFFFF;
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: -0.4px;
}
.vintage-table tbody tr + tr td {
    border-top: solid 2px rgb(255 255 255 / 30%);
}

.vintage-table .scroll-y {
    max-height: 570px;
    overflow-y: auto;
    padding-right: 10px;
}
.vintage-table .scroll-y::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}
.vintage-table .scroll-y::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 3px;
}
.vintage-table .scroll-y::-webkit-scrollbar-thumb {
    background-color: #545651;
    border-radius: 3px;
}
.vt-header {
    padding-right: 20px;
}
.font-1-5em {
    font-size: 1.5em !important;
}
.car-modal-box {
    border-radius: 9px;
    background-color: #212020;
    height: 170px;
    cursor: pointer;
}
.car-modals-wrap .lg-btn {
    width: 400px;
}
.perk-box {
    border-radius: 24px;
    background-color: #212020;
    height: 130px;
    padding: 34px;
}
.perk-icon {
    width: 60px;
}
.perk-box .sm-title {
    width: calc(100% - 60px);
    padding-left: 22px;
    color: #79BB30;
    font-size: 18px;
    letter-spacing: -0.36px;
    line-height: 23px;
}
.service-perks-wrap {
    padding: 80px 0;
}
.address-direction-wrap {
    background-color: #212020;
}
.ad-block {
    max-width: 650px;
    margin: 0 auto;
}
.py-100 {
    padding-top: 80px !important;
    padding-bottom: 100px !important;
}
.contact-wrap .gray-box {
    padding: 60px 140px;
}
.icon-img {
    width: 36px;
}

.community-banner-wrap .banner-flex {
    height: 360px;
}
.horsepower-block {
    max-width: 991px;
    margin: 0 auto;
}
.com-commmon-wrap {
    padding: 70px 0;
}
.com-common-box {
    border-radius: 24px;
    background-color: #212020;
    padding: 60px;
    overflow: hidden;
}
.lg-title{
    color: #FFFFFF;
    font-size: 2.2em;
    font-weight: 900;
    letter-spacing: 0.18px;
    line-height: 1.5em;
}
.cc-img img {
    max-width: 100%;
    border-radius: 20px;
}
.community-content .content {
    padding: 0 50px;
}
.community-content .content p {
    font-size: 1.4em;
}
.community-membership-wrap {
    padding-top: 110px;
    padding-bottom: 95px;
}
.font-12 {
    font-size: 12px !important;
}
.rb-xs-title {
    color: #CCCCCC;
    font-size: 12px;
    letter-spacing: -0.07px;
    line-height: 14px;
}
.rb-btn {
    border: 1px solid #545651;
    border-radius: 8px;
    background-color: #282828;
    color: #fff;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding: 11px 20px;
    transition: all 0.5s ease-in-out 0s;
}
.rb-btn:hover {
    border-color: #619826;
    color: #619826;
}
.rb-count {
    color: #CCCCCC;
    font-family: "Nunito Sans";
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 15px;
    text-align: center;
    padding: 0 30px;
}
.rb-left-icon {
    background: url(../img/arrow-left-icon.svg) no-repeat center center;
    background-size: 100%;
    width: 8px;
    height: 18px;
    display: inline-block;
    vertical-align: -5px;
}
.rb-right-icon {
    transform: rotate(180deg);
}
.rb-btn:hover .rb-left-icon {
    background: url(../img/arrow-left-icon-hover.svg) no-repeat center center;
    background-size: 100%;
}
.register-bid-btn {
    border-radius: 30px;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding: 10px 25px;
}
.rb-slider .owl-dots {
    display: none;
}
.rb-img {
    width: 150px;
}
.rb-img img {
    max-width: 150px;
    max-height: 65px;
}
.rb-slider .ps-4 {
    width: calc(100% - 150px);
}

.la-tagline-box {
    border-radius: 4px;
    background-color: #2E3033;
    padding: 10px 25px;
    margin-bottom: 36px;
}
.lat-left {
    border-right: 1px solid rgb(151 151 151 / 40%);
}
.lat-right {
    color: #CCCCCC;
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 16px;
}
.la-list-box {
    border: 1px solid #545651;
    border-radius: 24px;
    background-color: rgba(40,40,40,0.6);
    padding: 45px 60px;
    margin-bottom: 30px;
}
.lal-img {
    width: 400px;
}
.lal-content {
    width: calc(100% - 400px);
    padding-left: 90px;
}
.opacity-75 {
    opacity: 0.75 !important;
}
.clock-icon {
    background: url(../img/clock-icon.svg) no-repeat center center;
    background-size: 100%;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: -6px;
}
.lal-subtitle {
    opacity: 0.75;
    color: #FFFFFF;
    font-size: 17px;
    letter-spacing: -0.34px;
    line-height: 20px;
    font-weight: 400;
}
/* 
.ai-loading-block {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgb(0 0 0 / 80%);
    z-index: 9;
}
.ai-loading-block .h2 {
    color: #ccc;
    margin: 0;
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 0.8em;
  }
.ai-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.ai-loading span {
    display: inline-block;
    vertical-align: middle;
    width: .8em;
    height: .8em;
    margin: .35em;
    background: #007DB6;
    border-radius: .6em;
    animation: ailoading 1s infinite alternate;
  }
  
  .ai-loading span:nth-of-type(2) {
    background: #2A589C;
    animation-delay: 0.2s;
  }
  .ai-loading span:nth-of-type(3) {
    background: #A2C2F1;
    animation-delay: 0.4s;
  }
  .ai-loading span:nth-of-type(4) {
    background: #106EED;
    animation-delay: 0.6s;
  }
  .ai-loading span:nth-of-type(5) {
    background: #2A589C;
    animation-delay: 0.8s;
  }
  .ai-loading span:nth-of-type(6) {
    background: #A2C2F1;
    animation-delay: 1.0s;
  }
  .ai-loading span:nth-of-type(7) {
    background: #100345;
    animation-delay: 1.2s;
  }
  @keyframes ailoading {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
} */

.red-arrow {
    background: url(../img/up-arrow.svg) no-repeat center center;
    background-size: 100%;
}
.bid-price-status {
    width: 25px;
    height: 17px;
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translate(0, -14px);
}
.mb-100 {
    margin-bottom: 100px;
}
.off-retail-text {
    color: #D95D49;
    font-size: 12px;
    letter-spacing: -0.09px;
    line-height: 14px;
    text-align: right;
}
.off-retail-text img {
    max-width: 17px;
}
.lal-img img {
    border-radius: 12px;
}
.la-bottom>div:last-child {
    text-align: right;
}
.lal-content .green-btn {
    display: block;
    color: #fff;
}
.register-terms a {
    color: #629529;
}
.form-horizontal .checkbox {
    min-height: 27px;
}
.checkbox label {
    min-height: 20px;
    padding-left: 26px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
}
.checkbox input[type=checkbox]{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0 0 0;
    line-height: normal;
    position: absolute;
    margin-left: -26px;
    min-height: auto;
    width: 16px;
    height: 16px;
}
.brand-logo {
    min-width: 74px;
    max-width: 74px;
}
.brand-name {
    opacity: 0.4;
    color: #FFFFFF;
    font-size: 3em;
    font-weight: 900;
    letter-spacing: 1.5px;
}
.home-banner-wrap {
    height: 500px;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    position: relative;
    max-width: 100%;
    padding-top: 75%;
}
.home-banner-wrap video {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.home-overlay-pos {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 2;
    user-select: none;
}
.home-banner-pos {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 3;
}
.live-auction-left {
    background: #2E3033;
    padding: 7px 20px;
    height: 64px;
    border-radius: 3px 0 0 3px;
}
.live-auction-right {
    background: #2E3033;
    height: 64px;
    padding: 7px 30px;
    border-radius:  0 3px 3px 0;
    position: relative;
}
.live-auction-right:before {
    content: '';
    position: absolute;
    left: 0;
    top: 13px;
    bottom: 13px;
    border-left: solid 1px #5c5d5f;
}
.live-auction-value {
    font-size: 40px;
    font-weight: bold;
    line-height: 53px;
    text-align: right;
}
.live-auction-label {
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    font-weight: normal;
    width: 50px;
    text-align: left;
    padding-top: 16px;
    padding-left: 8px;
}
.live-auction-block .title {
    height: 16px;
    width: 110px;
    color: #cd290e;
    font-size: 12px;
    font-weight: 900;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.vehicle-aution-name {
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}
.vehicle-option-label {
    color: #333;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
}
.vehicle-option-value {
    color: #333333;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    text-align: right;
}
.gavel-icon img {
    max-width: 44px;
}
.vehicle-options tr th {
    color: #333333;
    font-size: 1em;
    letter-spacing: -0.34px;
    line-height: 1.2em;
    font-weight: 500;
    padding: 7px 0;
}
.vehicle-options tr td {
    color: #858585;
    font-size: 1em;
    letter-spacing: -0.34px;
    line-height: 1.2em;
    font-weight: 400;
    padding: 7px 0;
}
.note-text {
    font-size: 12px;
}
.place-bid-btn {
    padding: 13px 10px;
    text-transform: uppercase;
}
.bid-modal-body .tab-pane {
    border-radius: 5px;
    background: #fff;
    padding: 20px;
    border: solid 1px #e1e1e1;
}
.bid-modal-body .nav-tabs {
    border-bottom: none;
}
.bid-modal-body .nav-tabs .nav-link {
    border: none;
    background: #fff;
    bottom: 0;
    position: relative;
    color: #919191;
}
.bid-modal-body .nav-tabs .nav-item {
    margin: 0 10px;
}
.bid-modal-body .nav-tabs .nav-item.show .nav-link, 
.bid-modal-body .nav-tabs .nav-link.active {
    bottom: -1px;
    color: #79BB30;
    border: solid 1px #e1e1e1;
    border-bottom: none;
}
.border-l {
    border-left: solid 1px #e1e1e1;
}

.fv-carousel-block {
    position: relative;
}
.fv-carousel-block:before {
    content: '';
    background: url(../img/gradient-left.webp) no-repeat left top;
    background-size: 100% 100%;
    height: 100%;
    width: 90px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    opacity: 1;
}
.fv-carousel-block:after {
    content: '';
    background: url(../img/gradient-right.webp) no-repeat left top;
    background-size: 100% 100%;
    height: 100%;
    width: 90px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    opacity: 1;
}
.form-group>.custom-checkbox>input {
    border-radius: 0;
    min-height: inherit;
    display: inline-block;
    vertical-align: -3px;
    margin-right: 5px;
}
.featured-slider .slide-item {
    padding: 0 15px;
}

@keyframes jumpInfinite {
    0% {
        margin-top: -5px;
    }
    50% {
        margin-top: 5px;
    }
    100% {
        margin-top: -5px;
    }
}
.text-lred {
    color: #D95D49 !important;
}
.home-banner-wrap video {
    object-fit: cover;
    pointer-events: none;
}
.make-box {
    border-radius: 8px;
    background-color: #282828;
    padding: 14px 20px;
    border: solid 1px #282828;
}
.make-box input {
    width: 19px;
    height: 19px;
}
.font-13 {
    font-size: 13px !important;
}
.fw-300 {
    font-weight: 300 !important;
}
.make-box.selected {
    border-color: #79BB30;
}
.make-box-list {
    margin: 0 -20px;
}
.make-box-list > div {
    padding: 0 20px 15px;
}
.dark-theme-modal .modal-content {
    background: #161616;
    padding: 0;
    border: solid 1px #838181;
    border-radius: 0px;
}
.dark-theme-modal .modal-header {
    background: #212020;
    padding: 20px;
    border: none;
}
.dark-theme-modal .modal-title {
    color: #fff;
}
.dark-theme-modal .sm-box-close {
    color: #fff;
}
.dark-theme-modal .modal-footer {
    background: #2E3033;
    border: none;
}
.cs-checkbox input[type="checkbox"] {
    display: none;
}
.cs-checkbox label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}
.cs-checkbox input[type="checkbox"]:checked + label {
    background-color: #79BB30;
    border-color: #79BB30;
}
.cs-checkbox input[type="checkbox"]:checked + label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.register-to-bid-block .rb-img img {
    object-fit: cover;
}
.register-to-bid-block .col-lg-8.col-xl-6 > .d-flex {
    width: calc(100% - 120px);
}
.font-1-2em {
    font-size: 1.2em !important;
}
.w-350 {
    width: 350px;
}
.vehicle-desc {
    /* max-height: 67px;
    overflow-y: auto; */
    padding-right: 10px;
}
.vehicle-desc::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}
.vehicle-desc::-webkit-scrollbar-track {
    background-color: #ccc;
    border-radius: 3px;
}
.vehicle-desc::-webkit-scrollbar-thumb {
    background-color: #545651;
    border-radius: 3px;
}

.content-container {
    position: relative;
}

.content-text {
    transition: max-height 0.3s ease;
    overflow: hidden;
}

.read-more-button {
    background: none;
    border: none;
    color: #007bff; /* Button color */
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
    text-decoration: underline;
    margin-top: 5px; /* Space between content and button */
}
.grand-opening-wrap {
    background: url(../img/grand-opening-bg.webp) no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
}
.grand-opening-flex {
    height: 650px;
}
.grand-opening-wrap .sub-heading {
    color: #fff !important;
    font-size: 1.5em;
    margin-top: 18px;
    margin-bottom: 40px;
}
.grand-opening-wrap .mobile-app-form .form-control {
    padding: 20px 22px;
    border-radius: 32px;
    width: calc(100% - 179px);
}
.grand-opening-wrap .green-btn {
    border-radius: 32px;
    padding: 21px 20px;
    width: 159px;
}
.go-left {
    width: 500px;
}
.go-right {
    padding-left: 75px;
    width: calc(100% - 500px);
}
.m-title {
    position: absolute;
}
.m-title1 {
    top: 77px;
    left: -16px;
}
.m-title2 {
    top: 30px;
    left: 97px;
}
.m-title3 {
    top: 0;
    left: 320px;
}
.m-title4 {
    bottom: 0px;
    left: 0px;
}
.h-225 {
    height: 225px;
}
.content-container {
    position: relative;
    overflow: hidden;
}

.truncated-text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-line-break: after-white-space;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    position: relative;
}

.read-more-link {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #282828;
    padding-left: 5px;
    color: #619826;
    text-decoration: none;
    font-weight: bold;
}

@-webkit-keyframes brookLinAnimation {
    from {
      opacity: 0;
      -webkit-transform: scale(3);
      transform: scale(3);
    }
  
    50% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
}
@keyframes brookLinAnimation {
    from {
      opacity: 0;
      -webkit-transform: scale(3);
      transform: scale(3);
    }
  
    50% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
}
.brookLinAnimation {
    -webkit-animation-name: brookLinAnimation;
    animation-name: brookLinAnimation;
}
.h-read-more {
    text-decoration: none;
    font-weight: bold;
}

.join-mailing-btn {
    border: 2px solid #FFFFFF;
    border-radius: 23.5px;
    background-color: #79BB30;
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
    padding: 10px 47px 8px;
    color: #fff;
    text-decoration: none;
}
.join-mailing-btn:hover {
    color: #fff;
}
.dark-theme-modal .modal-content.gom-content {
    background: url(../img/grand-opening-modal-bg.webp) no-repeat center center !important;
    background-size: cover !important;
}
.go-label {
    color: #A7ACB1;
    font-family: "Nunito Sans";
    font-size: 15px;
    letter-spacing: -0.11px;
    line-height: 18px;
    margin-right: 24px;
}
.go-value {
    color: #FFFFFF;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.13px;
    line-height: 21px;
}
.mt-206 {
    margin-top: 166px;
}
.grand-open-img img {
    max-width: 360px;
}
.gom-content .main-heading {
    font-size: 2em;
}
.grand-opening-modal {
    opacity: 0;
}
.grand-opening-modal.fadeIn {
    -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.video-background {
    display: block;
}
.video-background.video-fade-in {
    -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: videoFadeIn;
  animation-name: videoFadeIn;
  opacity: 0.1;
}
@-webkit-keyframes videoFadeIn {
    from {
      opacity: 0.1;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes videoFadeIn {
    from {
      opacity: 0.1;
    }
  
    to {
      opacity: 1;
    }
  }

/* Parallex Slider */
.home-header {
    position: relative;
    z-index: 3;
}
.parallax-wrap {
    overflow: hidden;
}
#pinContainer,
#pinContainer1 {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

#pinContainer .panel,
#pinContainer1 .panel {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 2;
    overflow: hidden; 
}
.panel-inner {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
}
.panel.panel-banner-wrap {
    height: calc(100vh - 96px);
}
.panel span {
    position: relative;
    display: block;
    top: 50%;
    font-size: 80px;
}

.panel.bordeaux {
    background-color: #953543;
}
.scroll-down-btn {
    color: #FFFFFF;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20px;
    text-transform: uppercase;
    z-index: 2;
}
.scroll-down-btn img {
    width: 45px;
    height: 45px;
    margin-bottom: 15px;
    animation: scrollArrow 1.5s infinite;
}
.scroll-down-btn:hover {
    color: #fff;
}

@keyframes scrollArrow {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    50% {
        transform: translateY(10px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
.xxl-title {
    color: #fff;
    font-family: "Nunito Sans";
    font-size: 4.2em;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 1.5em;
    text-align: center;
}
.xl-title {
    color: #fff;
    font-family: "Nunito Sans";
    font-size: 2.5em;
    letter-spacing: 0;
    line-height: 1.3em;
    text-align: center;
}
.pi-pos {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
}
.xl-btn {
    font-size: 1.5em !important;
    line-height: normal;
    border-radius: 50px;
    padding: 17px 45px;
    font-weight: bold;
}
.mxw-990 {
    max-width: 990px;
}
.mxw-470 {
    max-width: 470px;
}
.panel-inside-bg {
    background: #282828;
}
.panel-inside-bg p {
    font-size: 1.1em;
}
.panel-inside-bg .fvb-list li {
    font-size: 1.1em;
    padding-left: 41px;
}

.panel-inside-bg .fvb-list li:before {
    content: '';
    height: 24px;
    width: 24px;
    background: url(../img/green-check-icons.svg) no-repeat center center;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: inherit;
}
.panel-inside-bg .fvb-list {
    margin-top: 40px;
    margin-bottom: 100px;
    min-height: inherit;
}
.add-overlay-bg {
    background-size: cover !important;
}
.add-overlay-bg:after {
    content: "";
    background-color: rgb(22 22 22 / 70%);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}
.add-overlay-bg .pi-pos {
    z-index: 1;
}

.panel-banner-wrap .banner-wrap {
    height: calc(100vh - 96px);
}
.panel-banner-wrap .home-banner-pos {
    top: 50%;
}
.featured-slider .slick-prev {
    z-index: 2;
    left: 15px;
    background: url(../img/arrow-right.webp) no-repeat center center;
    background-size: 100%;
    transform: translate(0, -50%) rotate(180deg);
    width: 55px;
    height: 55px;
}
.featured-slider .slick-next {
    z-index: 3;
    right: 15px;
    background: url(../img/arrow-right.webp) no-repeat center center;
    background-size: 100%;
    width: 55px;
    height: 55px;
}
.featured-slider .slick-prev:before, 
.featured-slider .slick-next:before {
    display: none;
}
.ais-Hits {
    background: #fff;
    border-radius: 5px;
    position: absolute;
    top: 100%;
    z-index: 1;
    left: 0;
    right: 0;
    max-height: 190px;
    overflow-y: auto;
}
.ais-Hits-item {
    padding: 9px 15px;
}
.ais-Hits::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
}
.ais-Hits::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 6px;
}
.copy-info .copy-tooltip {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 10px);
    transform: translate(-50%, 0);
    width: 90px;
    border-radius: 4px;
    background: #000;
    color: #fff;
    font-size: 12px;
    padding: 4px 0;
    text-align: center;
}
.copy-info .copy-tooltip:after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -10px;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    border-top: solid 8px #000;
    bottom: -8px;
}
.eca-title1 {
    border-radius: 8px;
    background-color: #79bb30;
    text-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    font-size: 4em;
    font-weight: 900;
    line-height: 1em;
    letter-spacing: -2.13px;
    color: #fff;
    text-transform: uppercase;
    padding: 14px 30px;
    display: inline-block;
}
.eca-title2 {
    border-radius: 8px;
    border: solid 1px #494e53;
    background-color: #282828;
    text-shadow: 0 2px 23px rgba(0, 0, 0, 0.5);
    font-size: 2.2em;
    font-weight: 900;
    line-height: 1em;
    letter-spacing: normal;
    color: #fff;
    text-transform: uppercase;
    padding: 14px 30px;
    display: inline-block;
}
.eca-pos {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
}
.eca-get-offer {
    border-radius: 24px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border: solid 1px #545651;
    background-color: rgba(40, 40, 40, 0.6);
    padding: 40px 50px;
    width: 460px;
}
.font-1-8em {
    font-size: 1.8em !important;
}
.font-2-2em {
    font-size: 2.2em !important;
}
.eca-get-offer .form-control {
    padding: 17px 41px 17px 17px;
    border-radius: 5px;
    background-color: #fff;
    background-color: #fff;
    font-size: 1.1em;
    color: #2e3033;
}
.eca-get-offer .form-control:focus {
    outline: none;
    box-shadow: none;
}
.eca-get-offer .lg-btn {
    padding: 15px 65px;
}
.eca-get-offer .form-group {
    margin-bottom: 22px;
}
.how-works-wrap {
    background-color: #161616;
    overflow: hidden;
}
.py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
}
.hw-circle {
    width: 68px;
    min-width: 68px;
    height: 68px;
    border: solid 2px #79bb30;
    text-align: center;
    line-height: 68px;
    border-radius: 50%;
}
.text-lgray {
    color: #717578 !important;
}
.hwr-box {
    padding: 10px 40px;
    border-radius: 24px;
    background-color: #212020;
    width: 100%;
    height: 122px;
}
.hwr-title {
    font-size: 1.1em;
    letter-spacing: -0.36px;
    color: #79bb30;
}
.hwr-icon {
    min-width: 31px;
}
.hw-left > .d-flex + .d-flex {
    margin-top: 80px;
}
.eca-count-box {
    padding: 45px 30px;
    border-radius: 16px;
    background-color: #212020
}
.recently-vehicles-wrap {
    padding: 80px 0;
}
.rpv-box {
    padding: 25px 20px 17px;
    border-radius: 24px;
    background-color: #212020;
}
.rpv-content {
    padding-top: 20px;
}
.sold-btn {
    padding: 3px 8px 3px 7px;
    border-radius: 13px;
    background-color: #bc1a2b;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.09px;
    text-align: center;
    color: #fafafa;
    width: 99px;
}
.rpv-img img {
    border-radius: 12px;
    max-height: 160px;
    width: 100%;
    object-fit: cover;
}
.get-offer-wrap {
    padding: 40px 0;
    background-color: #494e53;
}
.custom-search-box {
    width: 100%;
}
.get-offer-form {
    border-radius: 8px;
    background-color: #fff;
}
.get-offer-form .green-btn {
    width: 160px;
    text-align: center;
    border-radius: 0 6px 6px 0;
}
.get-offer-form .form-control::placeholder,
.eca-get-offer .form-control::placeholder,
.get-instent-form .form-control::placeholder {
    opacity: 0.5;
}
.get-offer-form .form-control:-ms-input-placeholder,
.eca-get-offer .form-control:-ms-input-placeholder,
.get-instent-form .form-control:-ms-input-placeholder {
    opacity: 0.5;
}
.get-offer-form .form-control::-ms-input-placeholder,
.eca-get-offer .form-control::-ms-input-placeholder,
.get-instent-form .form-control::-ms-input-placeholder {
    opacity: 0.5;
}
.faqs-wrap {
    padding: 80px 0;
}
.custom-accordion .accordion-item {
    border: none;
    border-radius: 6px;
    box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.1);
    background-color: #212020;
}
.custom-accordion .accordion-button.collapsed {
    padding: 24px 50px;
}
.custom-accordion .accordion-button {
    background: transparent;
    border: none;
    font-size: 21px;
    font-weight: bold;
    color: #fff;
    padding: 24px 50px 0;
}
.custom-accordion .accordion-button:focus {
    outline: none;
    box-shadow: none;
}
.custom-accordion .accordion-item + .accordion-item {
    margin-top: 20px;
}
.custom-accordion .accordion-body {
    color: #717578;
    padding: 15px 50px 24px;
}
.custom-accordion .accordion-button::after {
    background: url(../img/faq-icon-minus.svg) no-repeat center center;
    background-size: 100%;
    width: 32px;
    height: 32px;
}
.custom-accordion .accordion-button.collapsed::after {
    background: url(../img/faq-icon-plus.svg) no-repeat center center;
    background-size: 100%;
}

.get-instant-box {
    padding: 40px 20px;
    border-radius: 16px;
    border: solid 1px #717578;
    background-color: #212020;
}
.get-instent-form .form-group:first-child {
    margin-right: 16px;
}
.get-instent-form .form-group .form-control {
    border-radius: 35px;
    font-size: 1.1em;
    padding: 14px 25px;
    width: 487px;
}
.get-instent-form .form-group .lg-btn {
    padding: 13px 45px !important;
}
.get-instant-wrap {
    padding-bottom: 80px;
}
.eca-count-wrap .col-lg-3 + .col-lg-3 {
    border-left: solid 1px rgb(197 199 202 / 30%);
}
.appraise-car-box {
    max-width: 550px;
    padding: 40px 0 80px;
}
.appraise-car-box .md-title {
    margin-bottom: 40px;
}
.required-text {
    color: #898484;
    font-family: "Nunito Sans";
    font-size: 13px;
    letter-spacing: -0.09px;
    line-height: 18px;
    text-align: right;
}
.modal-footer {
    background-color: #212020;
}
.appraise-car-modal .modal-footer {
    background-color: #212020;
    padding: 21px 40px;
}
.mnw-195 {
    min-width: 195px;
}
.eca-back-btn {
    background: transparent;
    border: none;
    font-weight: bold;
    text-decoration: underline;
}
.browse-photo-box {
    padding: 30px 20px;
    border-radius: 5px;
    border: dashed 2px #717578;
    background-color: #212020;
}
.bpb-title {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.12px;
    color: #717578;
    text-transform: uppercase;
    margin: 12px 0;
}
.browse-photo-box .green-btn {
    border-radius: 4px;
    font-size: 12px;
    width: 200px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}
.browse-photo-box img {
    max-height: 80px;
}
.eca-wrap {
    height: 675px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
}
.uploaded-box {
    width: 100px;
    min-width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
}
.uploaded-box > img {
    object-fit: cover;
    width: 100px;
    height: 100px;
    max-height: 100px;
}
.upload-close {
    position: absolute;
    top: 2px;
    right: 2px;
    background: transparent;
    border: none;
}
.upload-close img {
    max-width: 20px;
    background: #fff;
    border-radius: 50%;
}
.form-group label.error {
    color: #ff0000;
    text-transform: inherit;
    font-weight: 700;
}
.total-conunt {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgb(0 0 0 / 50%);
    font-size: 1.4em;
    color: #fff;
    font-weight: 700;
}
.tc-label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.get-offer-form .form-group:first-child {
    width: calc(100% - 255px);
}
.get-offer-form .form-group:first-child input {
    height: 56px;
    min-height: inherit;
    background: transparent;
    border: none;
}
.get-offer-form .green-btn {
    height: 56px;
}