@media (min-width: 767px) {
    .sales-main-block {
        background: url(../img/sales-full-bg.webp) no-repeat center center;
    }
}
@media (max-width: 1420px) {
    .srp-filter-btn {
        width: 122px;
    }
}
@media (max-width: 1400px) {
    body {
        font-size: 14px;
    }
    .rb-btn {
        padding: 17px 11px;
    }
    .rb-count {
        padding: 0 15px;
    }
    .get-offer-form .form-group:first-child {
        width: calc(100% - 220px);
    }
    .get-offer-form .green-btn {
        width: 220px !important;
        padding: 5px 15px;
    }
}
@media (max-width: 1280px) {
    .mxw-1230 {
        max-width: 970px;
    }
    .rb-btn {
        padding: 10px 11px;
    }
    .go-left {
        width: 400px;
    }
    .go-right {
        width: calc(100% - 400px);
    }
    .xxl-title {
        font-size: 3.2em;
    }
    .xl-title {
        font-size: 1.8em;
    }
    .xl-title.my-4 {
        margin-top: 0.7rem !important;
        margin-bottom: 0.7rem !important;
    }
    .xl-title.my-4 + .mt-5 {
        margin-top: 2rem !important;
    }
}
@media (max-width: 1199px) {
    .main-menu ul li a {
        padding: 0 10px;
    }
    .mp-box {
        height: 150px;
        width: 150px;
        padding-top: 1.7rem !important;
    }
    .pro-bottom .green-btn {
        padding: 8px 20px;
    }
    .product-list .col-md-4 {
        width: 50%;
    }
    .product-list .col-md-8 {
        width: 100%;
    }
    .srp-filter-btn {
        padding: 8px 10px;
        width: 102px;
    }
    .srp-filter-btn img {
        margin-right: 12px;
        max-width: 18px;
        max-height: 18px;
        top: -2px;
        position: relative;
    }
    .service-banner-wrap {
        padding-top: 50px;
        padding-bottom: 50px;
        height: auto;
    }
    .service-banner-img {
        position: relative;
        right: inherit;
        bottom: inherit;
        text-align: center;
    }
    .service-banner {
        height: auto;
        text-align: center;
    }
    .sf-box {
        padding: 40px 40px;
    }
    .sf-img img {
        height: 500px;
    }
    .sub-mega-menu {
        width: 830px;
    }
    .community-banner-wrap .banner-flex {
        height: 250px;
    }
    .lal-img {
        width: 300px;
    }
    .lal-img img {
        height: 234px;
        object-fit: cover;
    }
    .lal-content {
        width: calc(100% - 300px);
        padding-left: 70px;
    }
    .banner-box {
        padding: 0 22px;
    }
    .h-225 {
        height: 181px;
    }
    .m-title1 {
        left: -11px;
    }
    .m-title1 img {
        max-height: 46px;
    }
    .m-title2 {
        top: 45px;
        left: 77px;
    }
    .m-title2 img {
        max-height: 60px;
    }
    .m-title3 {
        top: 23px;
        left: 244px;
    }
    .m-title3 img {
        max-height: 52px;
    }
    .m-title4 {
        bottom: -20px;
        left: 4px;
    }
    .m-title4 img {
        max-height: 117px;
    }
    .eca-title1 {
        font-size: 3em;
    }
    .eca-title2 {
        font-size: 2em;
    }
    .get-offer-form .green-btn {
        width: 190px !important;
    }
    .get-offer-form .form-group:first-child {
        width: calc(100% - 190px);
    }
}
@media (max-width: 1025px) {
    .vdpa-block {
        display: block;
    }
    .vdpa-block .col-md-4 {
        width: 100%;
        margin-bottom: 50px;
    }
    .vdpa-block .col-md-8 {
        width: 100%;
    }
    .contact-flex {
        margin-left: -30px;
        margin-right: -30px;
    }
    .contact-flex .col-md-6 {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media (max-width: 992px) {
    /* Mobile Menu */
    .mobile-inner-header {
        display: block;
        margin-top: 10px;
        margin-left: 10px;
        width: 40px;
        height: 24px;
        position: relative;
        right: 0;
        top: 0;
    }

    .mobile-inner-nav {
        background-color: rgba(3, 28, 62, .9);
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding-top: 30px;
        padding-bottom: 80px;
        display: none;
        z-index: 9;
        height: 100%;
        overflow: auto
    }

    .mobile-inner-header-icon {
        color: #fff;
        font-size: 25px;
        text-align: center;
        float: right;
        position: relative;
        -webkit-transition: background .5s;
        -moz-transition: background .5s;
        -o-transition: background .5s;
        transition: background .5s;
        margin-top: 6px
    }

    .mobile-inner-header-icon {
        color: #fff;
        height: 20px;
        font-size: 25px;
        text-align: center;
        float: right;
        width: 40px;
        position: relative;
        -webkit-transition: background .5s;
        -moz-transition: background .5s;
        -o-transition: background .5s;
        transition: background .5s;
        z-index: 99;
        margin-top: 4px
    }

    .mobile-inner-header-icon:hover {
        cursor: pointer
    }

    .mobile-inner-header-icon span {
        position: absolute;
        left: calc((100% - 20px)/ 2);
        top: calc((100% - 22px)/ 2);
        width: 30px;
        height: 2px;
        background-color: #fff;
    }

    .mobile-inner-header-icon.active span:nth-child(1) {
        transform: translateY(4px) rotate(0)
    }

    .mobile-inner-header-icon.active span:nth-child(2) {
        transform: translateY(-4px) rotate(0)
    }

    .mobile-inner-header-icon.active span:nth-child(3) {
        display: none;
    }

    .mobile-inner-header-icon.active span:nth-child(1) {
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: clickfirst
    }

    .mobile-inner-header-icon.active span:nth-child(2) {
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: clicksecond
    }

    @keyframes clickfirst {
        0% {
            transform: translateY(4px) rotate(0)
        }

        100% {
            transform: translateY(0) rotate(45deg)
        }
    }

    @keyframes clicksecond {
        0% {
            transform: translateY(-4px) rotate(0)
        }

        100% {
            transform: translateY(0) rotate(-45deg)
        }
    }

    .mobile-inner-header-icon-out span:nth-child(1) {
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: outfirst
    }

    .mobile-inner-header-icon-out span:nth-child(2) {
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: outsecond;
        margin-top: 3px;
        top: calc((100% - 20px)/ 2)
    }

    .mobile-inner-header-icon.active span:nth-child(2) {
        top: calc((100% - 28px) / 2);
    }

    .mobile-inner-header-icon-out span:nth-child(3) {
        display: block;
        top: calc((100% - 17.5px)/ 2)
    }

    @keyframes outfirst {
        0% {
            transform: translateY(0) rotate(-45deg)
        }

        100% {
            transform: translateY(-4px) rotate(0)
        }
    }

    @keyframes outsecond {
        0% {
            transform: translateY(0) rotate(45deg)
        }

        100% {
            transform: translateY(4px) rotate(0)
        }
    }

    .mobile-inner-header-icon.active {
        z-index: 10;
        position: fixed;
    }

    .mobile-inner-header-icon.mobile-inner-header-icon-click span {
        background-color: #000;
    }

    .menu-block {
        background: #161616;
        position: fixed;
        z-index: 10;
        top: 0;
        right: -100%;
        width: 300px !important;
        transition: all .5s ease-in-out 0s;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 20px;
        padding-bottom: 0;
        border-top: none;
        overflow-y: auto;
        height: 100%;
        margin-left: 0;
        display: block !important;
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    }

    .menu-block.active {
        right: 0;
    }

    .main-menu ul {
        margin: 0;
        width: 100%;
        text-align: left;
        display: block !important;
        padding: 15px 0;
    }

    .main-menu>ul>li {
        display: block;
        padding: 0;
        border-bottom: solid 1px #646060;
    }
    .main-menu>ul>li:last-child {
        border-bottom: none;
    }
    .main-menu ul li a {
        padding: 13px 20px 13px 30px;
        display: block;
        font-weight: bold;
        font-size: 16px;
    }
    .sub-mega-menu .icon-img {
        display: none;
    }
    .menu-arrow-icon {
        float: right;
        background: url(../img/down-arrow.webp) no-repeat center center;
        background-size: 100%;
        transform: rotate(-90deg);
    }
    .main-menu li.open a .menu-arrow-icon,
    .main-menu li.open:hover a .menu-arrow-icon {
        transform: rotate(0deg);
    }
    .sub-mega-menu .md-title {
        padding-left: 0 !important;
        opacity: 0.5;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.07px;
        line-height: 16px;
        text-transform: uppercase;
    }
    .login-block {
        text-align: center;
        margin-top: 40px;
        background-color: #282828;
        padding: 20px 30px;
        text-align: left;
    }
    .login-block .green-btn {
        width: 140px;
    }
    .overflow-active {
        overflow: hidden;
    }
    .overflow-bg {
        background: rgb(0 0 0 / 20%);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: none;
        z-index: 1;
    }
    .overflow-bg.active {
        display: block;
    }
    .main-menu ul.sub-mega-menu {
        position: relative;
        right: inherit;
        top: inherit;
        width: 100%;
        padding: 0;
        border: none;
        background: transparent;
        display: none !important;
    }
    .main-menu ul li:hover .sub-mega-menu {
        display: none !important;
    }
    .main-menu li.open ul.sub-mega-menu,
    .main-menu ul li.open:hover .sub-mega-menu {
        display: block !important;
    }
    .sub-mega-menu .smm-col:nth-child(2),
    .sub-mega-menu .smm-col {
        width: 100% !important;
        padding: 0 0 0 35px;
        border: none;
    }
    .main-menu .inside-menu {
        padding-top: 5px;
    }
    .sub-mega-menu li>.d-flex {
        display: block !important;
    }
    .sub-mega-menu .smm-col:nth-child(2) .inside-menu {
        width: 100%;
    }
    .vdp-main .pe-5 {
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
    }
    .btn-overlay {
        bottom: -22px;
    }
    .banner-wrap {
        background: url(../img/mob_hero_banner.webp) center center no-repeat !important;
        background-size: cover !important;
    }
    .banner-flex {
        display: block !important;
    }
    .banner-wrap .w-55,    
    .banner-wrap .w-45 {
        width: 100% !important;
    }
    .banner-wrap .banner-title {
        text-align: center !important;
        padding-bottom: 50px;
    }
    .banner-wrap .banner-title br {
        display: none;
    }
    .featured-slider .owl-stage {
        max-width: 720px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .featured-slider .owl-stage .owl-item {
        margin-right: 0 !important;
        width: 50% !important;
        padding: 0 15px 30px;
    }
    .featured-vehicles-list .w-330 {
        width: 240px;
    }
    .mp-box {
        height: 110px;
        width: 110px;
        padding-top: 1.2rem !important;
    }
    .mp-icon {
        min-height: 45px;
        margin-bottom: 0.5rem !important;
    }
    .mp-icon img {
        max-width: 45px;
        max-height: 45px;
    }
    .filter-btn {
        border: none;
        background: transparent;
        color: #79BB30;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.28px;
        line-height: 16px;
    }
    .filter-btn img {
        margin-left: 5px;
    }
    .srp-filter-blk {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        padding: 0;
        background-color: #161616;
        display: none;
    }
    .srp-filter-blk.active {
        display: block;
    }
    .srp-filter-header {
        background-color: #212020;
        padding: 22px 10px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        color: #fff;
    }
    .srp-filter-body {
        display: block !important;
        padding: 20px;
    }
    .srp-filter-btn {
        border-radius: 8px;
        background-color: #282828;
        border: none;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 21px;
        text-align: left;
        padding: 19px 20px;
        width: 100%;
        margin-top: 15px;
        justify-content: left;
    }
    .srp-filter-btn img {
        min-width: 18px;
    }
    .srp-fl-arrow {
        background: url(../img/filter-right-arrow.svg) no-repeat center center;
        background-size: 100%;
        display: inline-block;
        width: 10px;
        height: 18px;
        margin-left: auto;
    }
    .sf-close-icon {
        background: transparent;
        border: none;
        position: absolute;
        right: 17px;
        top: 20px;
    }
    
    .product-list .col-md-4 {
        width: 100%;
    }
    .pro-bottom {
        padding: 16px;
    }
    .pro-full-width {
        display: block !important;
        padding: 0;
    }
    .pro-full-width .pro-top {
        min-width: 260px;
    }
    .pro-full-width .pro-top img {
        border-radius: 20px;
    }
    .pro-full-width .sm-title {
        font-size: 0.9em !important;
    }
    .pro-full-width .mt-5.pt-2 {
        margin-top: 0 !important;
    }
    .pro-full-width .mt-5.pt-2 .xs-title,
    .pro-full-width .mt-5.pt-2 .sm-title {
        font-size: 1.4em;
    }
    .pro-full-width .mt-5.pt-2 .d-flex  .sm-title {
        font-size: inherit !important;
    }
    .pro-full-width .pro-bottom .green-btn {
        padding: 10px 20px;
    }
    .lt-main-title {
        font-size: 1.7em;
        margin-bottom: 1rem !important;
    }
    .product-block.sticky .srp-filter-block {
        padding: 14px 0;
    }
    .search-box .form-control {
        height: 38px;
    }
    .search-icon {
        height: 38px;
        background-size: 16px 16px;
    }
    .srp-wrap>.container>.d-flex.mb-4 {
        margin-bottom: 0.5rem !important;
    }
    .sticky-filter-btn {
        display: none;
    }
    .product-block.sticky .sticky-filter-btn {
        display: block;
        white-space: nowrap;
        width: 120px;
        text-align: right;
    }
    .product-list .col-md-4, 
    .product-list .col-md-8 {
        margin-top: 20px;
    }
    .srp-filter-block .col-md-4 {
        width: 100%;
    }
    .vdpa-table tr th {
        font-size: 1.2em;
    }
    .header-wrap {
        z-index: 5;
        position: relative;
    }
    .mxw-1230 {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .service-featured-wrap .col-md-6,
    .service-from .col-md-6  {
        width: 100%;
    }
    .service-banner-wrap {
        padding-bottom: 30px;
    }
    .service-featured-wrap .col-md-6 + .col-md-6 {
        margin-top: 30px;
    }
    .contact-flex {
        margin-left: 0px;
        margin-right: 0px;
        display: block;
    }
    .contact-flex .col-md-6 {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
    }
    .contact-flex .col-md-6:first-child {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }
    .contact-banner .main-heading {
        text-align: center;
    }
    .contact-banner .md-title {
        text-align: center;
        margin-bottom: 20px;
    }
    .community-banner-wrap .banner-flex {
        display: flex !important;
    }
    .com-common-box {
        display: block !important;
    }
    .com-common-box>.w-50 {
        width: 100% !important;
    }
    .com-common-box .w-50.pe-5 {
        padding-right: 0 !important;
        padding-bottom: 20px;
    }
    .cc-img {
        text-align: left !important;
    }
    .horsepower-block {
        max-width: 100%;
        padding: 0 20px;
    }
    .com-common-box .w-50.ps-5 {
        padding-left: 0 !important;
        padding-top: 20px;
    }
    .community-banner-wrap {
        background: url(../img/mob-community-hero-bg.webp) no-repeat center center !important;
        background-size: cover !important;
    }
    .la-list-box {
        display: block !important;
        padding: 0;
    }
    .lal-img,
    .lal-img img {
        width: 100%;
    }
    .lal-img img {
        border-radius: 24px 24px 0 0;
    }
    .lal-content {
        width: 100%;
        padding: 16px;
    }
    .lal-content .lg-title.font-2em {
        font-size: 16px !important;
        font-weight: bold !important;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 7px !important;
    }
    .lal-content .md-title {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
        font-weight: 400 !important;
        margin-bottom: 12px !important;
    }
    .lal-content .xs-title {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 12px;
    }
    .bid-price-status {
        width: 17px;
        height: 12px;
        top: 50%;
        transform: translate(0, -8px);
        left: 0;
    }
    .la-bottom .lg-title {
        padding-left: 27px;
        font-size: 24px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 29px;
    }
    .lal-subtitle {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
        margin-bottom: 8px;
    }
    .la-bottom .green-btn.lg-btn {
        font-size: 12px !important;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
        padding: 8px 25px;
    }
    .la-bottom {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
    .lal-content .xs-title.font-1-1em {
        font-size: 15px !important;
    }
    .clock-icon.me-3 {
        margin-right: 0.5rem !important;
    }
    .home-banner-wrap {
        background: transparent !important;
    }
    .banner-box {
        visibility: visible !important;
        animation-name: zoomIn !important;
    }
    .grand-opening-flex {
        display: block !important;
        height: auto;
        padding: 60px 0;
    }
    .go-left {
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
    }
    .go-right {
        width: 100%;
        padding-left: 0;
        text-align: center;
    }
    .join-mailing-wrap .service-from {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .home-banner-wrap {
        height: 750px;
    }
    .banner-wrap .w-55 {
        max-width: 382px;
        margin: 0 auto 55px;
    }
    .panel-banner-wrap .banner-wrap {
        height: auto;  
    }
    .home-banner {
        padding-bottom: 340px !important;
        background: #161616 !important;
    }
    .mxw-470 {
        max-width: 280px;
    }
    .panel-inside-bg .fvb-list li {
        margin-bottom: 12px;
    }
    .panel-inside-bg .fvb-list {
        margin-top: 15px;
        margin-bottom: 30px;
    }
    .xl-btn {
        font-size: 16px !important;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
        padding: 14px 55px !important;
    }
    .panel-banner-wrap {
        position: relative;
    }
    .img-100vh img {
        object-fit: cover;
        height: 100vh;
    }
    .eca-pos-flex {
        display: block !important;
        text-align: center;
    }
    .eca-get-offer {
        margin: 25px auto 0;
    }
    .eca-pos {
        position: absolute;
        left: 0;
        top: 70px;
        transform: inherit;
        width: 100%;
    }
    .how-works-wrap {
        padding-top: 290px !important;
    }
    .hw-left > .d-flex + .d-flex {
        margin-top: 0;
    }
    .how-works-wrap .mb-5 {
        margin-bottom: 1.5rem !important;
    }
    .hw-left > .d-flex {
        margin-bottom: 25px;
    }
    .hw-left > .d-flex + .d-flex {
        margin-top: 50px;
    }
    .eca-count-wrap .col-lg-3 + .col-lg-3   {
        border-left: none;
    }
    .eca-count-wrap .col-lg-3:first-child,
    .eca-count-wrap .col-lg-3:nth-child(2) {
        border-bottom: solid 1px rgb(197 199 202 / 30%);
        padding-bottom: 20px !important;
        margin-bottom: 20px;
    }
    .pl-flex {
        display: block !important;
    }
    .get-offer-form {
        width: 100% !important;
        margin-top: 20px;
    }
    .get-instant-box .d-inline-flex {
        display: block !important;
        text-align: center;
    }
    .get-instant-box .green-btn {
        text-align: center;
        display: inline-block;
        width: auto !important;
    }
    .get-instent-form .form-group .form-control {
        margin: 0 auto 15px
    }
    .hw-right {
        margin-top: 15px;
    }
    .hw-right .g-5 {
        --bs-gutter-y: 1.5rem;
        --bs-gutter-x: 1.5rem;
    }
    .eca-wrap {
        height: 398px;
        overflow: inherit;
    }
    .pro-box .after-hover {
        width: 100%;
        margin-left: -50%;
    }

}
@media (max-width: 768px) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 100%;
    }
    .modal-dialog {
        margin: 0;
    }
    .custom-modal .modal-content.register-content {
        padding: 0;
        background: #fff;
        border: none;
    }
    .register-title {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        padding: 15px 0;
        background-color: #23262B;
        color: #ffffff;
    }
    .register-content .modal-body {
        padding: 0;
    }
    .register-wrapper {
        background: #E4E3E3;
        padding: 27px 30px;
        background-color: #353A40;
        color: #ffffff;
        min-height: calc(100vh - 59px);
    }
    .register-left {
        padding: 0 0 40px;
    }
    .or {
        left: 50%;
        right: inherit;
        top: inherit;
        bottom: -16px;
        transform: translate(-50%, 0);
    }
    .form-group input {
        border-radius: 5px;
    }
    .register-right {
        padding: 30px 0;
        border-left: none;
        border-top: 1px solid #999999;
    }
    .mt-114 {
        margin-top: 26px !important;
    }
    .register-content .sm-box-close {
        font-size: 20px;
        position: relative;
        top: 18px;
        right: 15px;
    }
    .register-form-title {
        font-size: 26px;
        color: #fff;
    }
    .custom-modal .modal-content {
        border-radius: 0;
        border: none;
        padding: 0;
        background-color: #23262B;
    }
    .custom-modal .modal-header {
        padding: 13px 10px;
        border-bottom: none;
        background-color: #23262B;
    }
    .custom-modal .modal-body {
        background-color: #353A40;
        color: #fff;
        min-height: calc(100vh - 53px);
    }
    .norton-badge {
        background-position: calc(100% - 13px) center;
    }
    .cc-icons {
        background-position: 13px 0;
    }
    .custom-modal .modal-title {
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        text-align: center;
        color: #ffffff;
    }
    .forgot-modal .register-about {
        font-size: 18px;
        background: transparent;
        margin-top: 0 !important;
        padding-top: 40px !important;
        padding-left: 15px;
        padding-right: 15px;
    }
    .forgot-modal .register-form-group.mt-4 {
        margin-top: 0 !important;
    }
    .forgot-modal .register-wrapper {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
    }
    .register-content.forgot-modal .modal-body {
        min-height: 100vh;
    }
    .market-analysis-modal .modal-body {
        min-height: calc(100vh - 53px);
    }
    .add-whishlist-modal .modal-body {
        min-height: calc(100vh - 53px);
    }
    .custom-breadcrumb.mb-5.ps-4 {
        margin-bottom: 15px !important;
        padding-left: 0 !important;
    }
    .footer-wrap .mt-5 {
        margin-top: 1.5rem !important;
    }
    .footer-wrap {
        text-align: center;
        padding: 35px 0 35px 0;
    }
    .footer-wrap .col-md-6.d-flex {
        display: block !important;
        padding-top: 30px;
    }
    .footer-menu {
        width: 100% !important;
        margin-top: 0;
    }
    .register-to-bid-block {
        height: auto;
    }
    .register-to-bid-block .row > div {
        padding: 10px 0 0;
        text-align: center !important;
    }
    .featured-slider .owl-stage {
        max-width: 100%;
    }
    .banner-title {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
    }
    .banner-wrap .banner-title {
        padding-bottom: 40px;
    }
    .banner-box.active {
        border-radius: 31px;
    }
    .sm-title {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
    }
    .main-heading {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 27px;
    }
    .featured-slider .fvb-bottom {
        display: flex;
        flex-wrap: wrap;
    }
    .featured-slider .fvb-bottom>.sm-title {
        margin-bottom: 20px !important;
    }
    .featured-slider .fvb-bottom .xs-title.mb-2 {
        width: 50%;
        margin-bottom: 0 !important;
    }
    .featured-slider .fvb-bottom .d-flex {
        width: 50%;
    }
    .featured-slider .fvb-bottom .d-flex .sm-title {
        text-align: right;
        width: 100%;
    }
    .featured-slider .fvb-bottom .d-flex>div:last-child {
        display: none;
    }
    .featured-slider .owl-dots {
        display: none;
    }
    .w-330 {
        width: 240px;
    }
    .featured-vehicles-list .main-heading {
        font-size: 24px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 29px;
    }
    .fvb-list {
        margin-top: 20px;
        min-height: inherit;
        margin-bottom: 40px;
    }
    .fvb-list li {
        margin: 0 0 15px;
    }
    .featured-vehicles-list .fvb-bottom {
        padding: 20px 24px 33px;
    }
    
    .featured-slider .slide-item  {
        padding: 0 10px;
    }
    .fv-carousel-block:before {
        width: 40px;
    }
    .fv-carousel-block:after {
        width: 40px;
    }
    .featured-vehicles-wrap {
        padding: 30px 0 60px;
    }
    .featured-vehicles-wrap .text-center.mt-3 {
        margin-top: 0 !important;
    }
    .mt-44 {
        margin-top: 34px !important;
    }
    .mobile-app-wrap {
        padding: 45px 0;
    }
    .mobile-app-wrap .col-md-6:last-child {
        text-align: center;
        padding-top: 35px;
    }
    .mobile-app-wrap .d-flex {
        display: block !important;
    }
    .mobile-app-form .form-control {
        width: 100%;
        border-radius: 32px;
        margin-bottom: 15px;
    }
    .mobile-app-form .green-btn {
        border-radius: 32px;
        width: 100%;
    }
    .mobile-app-wrap .main-heading {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 36px;
        max-width: 260px;
        margin-left: auto;
        margin-right: auto;
    }
    .mobile-app-wrap .sm-title {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 38px !important;
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
    }
    .mobile-app-wrap .sm-title br {
        display: none;
    }
    .mp-flex {
        flex-wrap: wrap;
    }
    .mp-flex .mp-col {
        width: calc(33.33% - 10px);
        text-align: center;
        margin-bottom: 7px;
    }
    .mp-box {
        margin: 0 auto;
    }
    .become-member-wrap {
        height: 500px;
    }
    .become-member-wrap .main-heading {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 41px;
        text-align: center;
    }
    .membership-perks-wrap {
        padding: 60px 0 50px;
    }
    .banner-box {
        height: auto;
        padding: 18px 34px;
    }
    .banner-box > .d-flex  {
        height: auto;
    }
    .bb-content p {
        display: none;
    }
    .bb-content .bb-title.mb-2 {
        margin-bottom: 0 !important;
    }
    .mob-bb-content {
        display: block;
        color: #FFFFFF;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        padding-top: 15px;
    }
    .banner-box + .banner-box {
        margin-top: 15px;
    }
    .footer-wrap .main-logo {
        left: inherit;
    }
    .main-logo img {
        max-height: 40px;
    }
    .mp-box {
        padding-top: 1.5rem !important;
    }
    .mp-icon {
        min-height: 32px;
    }
    .mp-icon img {
        max-width: 32px;
        max-height: 32px;
    }
    .bb-title {
        font-size: 1.3em;
    }
    .bb-icon {
        min-width: 32px;
    }
    .bb-icon img {
        max-width: 32px;
    }
    .header-left .main-logo {
        left: -15px;
        position: relative;
    }
    .auction-header-flex {
        flex-wrap: wrap;
        margin-bottom: 0.75rem !important;
    }
    .auction-header-flex>div {
        width: 100%;
        text-align: left !important;
    }
    .auction-header-flex .d-inline-flex {
        display: flex !important;
        justify-content: space-between;
    }
    .mobile-vdp-nav {
        margin-top: 30px;
    }
    .mobile-vdp-nav li {
        font-size: 18px;
        color: #fff;
        padding: 15px 25px;
        cursor: pointer;
        border-top: 1px solid #cccccc;
        cursor: pointer;
    }
    .mobile-vdp-nav li:last-child {
        border-bottom: 1px solid #cccccc;
    }
    .mob-vdpa-modal {
        z-index: 99;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: none;
        padding: 0 !important;
        border: none;
        border-radius: 0;
        background-color: #353A40;
        color: #ffffff;
        margin-bottom: 0 !important;
    }
    .mob-vdpa-modal.open {
        display: block;
    }
    .mob-vdpa-modal .modal-header {
        background-color: #23262B;
        color: #ffffff;
        border-bottom: 0;
    }
    .mob-vdpa-modal .modal-title {
        font-size: 18px;
        text-align: center;
        width: 100%;
    }
    .vdpa-modal-body {
        padding: 20px 15px;
        overflow-y: auto;
        max-height: calc(100% - 59px);
    }
    .vdpa-modal-body .wow {
        visibility: visible !important;
    }
    .auction-tabs .nav-tabs {
        display: none;
    }
    .auction-tabs .tab-content {
        padding: 0;
        background: transparent;
    }
    .auction-tabs .fade:not(.show) {
        display: block;
        opacity: 1;
    }
    .seller-tab-content {
        display: none !important;
    }
    .tab-title {
        color: #ffffff;
        font-size: 16px;
        font-weight: 900;
        line-height: 22px;
        text-transform: uppercase;
        padding: 16px 0;
    }
    .auction-tabs .tab-pane {
        padding-bottom: 30px;
    }
    .auction-table tr td {
        border-top: 1px solid rgba(204, 204, 204, 0.4);
    }
    .auction-table tr:last-child td {
        border-bottom: 1px solid rgba(204, 204, 204, 0.4);
    }
    .bid-history-scroll {
        max-height: calc(100vh - 119px);
    }
    .lg-btn {
        font-size: 1.1em !important;
        padding: 9px 30px;
    }
    .green-btn {
        padding: 9px 25px !important;
    }
    .mob-ask-question .text-end {
        text-align: left !important;
    }
    .mob-ask-question .attact-photo-btn {
        padding-left: 0;
        margin-bottom: 10px;
        text-align: left;
    }
    .mob-ask-question .green-btn {
        width: 100%;
    }
    .service-from .form-control {
        height: 40px;
    }
    .register-about {
        color: #fff;
    }
    .vdp-banner .vdp-banner-flex {
        display: block !important;
    }
    .vdp-banner-flex>.w-50 {
        width: 100% !important;
        padding: 0 0 !important;
    }
    .vdp-banner-info {
        flex-wrap: wrap;
    }
    .vdp-banner-info>div {
        width: 100%;
    }
    .vdp-banner-info .vdp-md-title {
        width: 100%;
        font-size: 28px;
        font-weight: 900;
        letter-spacing: -0.56px;
        line-height: 34px;
    }
    .vdp-banner-info .sm-title {
        font-size: 22px;
        letter-spacing: -0.44px;
        line-height: 26px;
        margin-bottom: 0.5rem !important;
    }
    .vdp-banner-info .xs-title {
        opacity: 0.75;
        color: #FFFFFF;
        font-size: 17px;
        letter-spacing: -0.34px;
        line-height: 20px;
    }
    .vdp-banner-info .green-btn.lg-btn {
        padding: 12px 20px !important;
        font-size: 20px !important;
        margin-top: 5px;
        width: 100%;
    }
    .vdp-gallery-tab .nav-tabs .nav-link {
        padding: 8px 15px;
    }
    .vdp-gallery-tab .tab-content.mt-5 {
        margin-top: 1.5rem !important;
    }
    .vdpa-box {
        padding: 15px !important;
    }
    .mob-vdpa-modal {
        padding: 0 !important;
    }
    .vdp-gallery-tab .nav-tabs li {
        margin: 0;
    }
    .vdp-gallery-tab .nav-tabs .nav-link {
        font-size: 1.1em;
    }
    .w-300 {
        width: 150px;
    }
    .vdp-banner-flex .w-60 {
        width: 100% !important;
    }
    .vdp-banner-flex .w-40 {
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .vdp-banner-flex {
        margin-top: -30px;
    }
    .font-2-8em {
        font-size: 2em !important;
    }
    .service-banner .main-heading {
        font-weight: 900;
        text-transform: inherit !important;
        max-width: 312px;
        margin: 0 auto;
        line-height: 34px;
    }
    .service-banner .service-md-title {
        font-size: 1.4em;
    }
    .service-md-title {
        margin-top: 0.5rem !important;
    }
    .profit-margins-wrap .pm-title img {
        max-width: 60px;
    }
    .pm-title {
        font-size: 2.2em;
    }
    .pm-title.mt-5.pt-4 {
        margin-top: 1rem !important;
    }
    .sf-box {
        padding: 50px 32px;
    }
    .sfb-title {
        font-size: 1.7em;
    }
    .profit-margins-wrap .d-flex {
        flex-wrap: wrap;
    }
    .profit-margins-wrap .d-flex>div {
        width: 33.33%;
        text-align: center;
    }
    .profit-margins-wrap .pm-title {
        min-height: 90px;
    }
    .profit-margins-wrap .pm-title>div {
        width: 350px;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .reliable-block {
        margin-top: 1rem !important;
    }
    .reliable-block .col-md-3 {
       padding: 25px 0 !important;
    }
    .reliable-block .col-md-3 + .col-md-3 {
        border-top: solid 1px rgb(255 255 255 / 30%);
        border-left: none;
    }
    .pm-logos img {
        max-height: 54px;
        max-width: 136px;
    }
    .pm-logos>div {
        margin: 20px 0;
    }
    .profile-logos-wrap {
        padding-bottom: 0;
    }
    .reliable-title {
        font-size: 18px;
        line-height: 32px;
        font-weight: bold;
        max-width: 315px;
        margin: 0 auto;
    }
    .reliable-value {
        font-size: 50px;
        font-weight: bold;
        letter-spacing: -1.01px;
        line-height: 60px;
        margin-bottom: 0.5rem !important;
    }
    .reliable-label {
        font-size: 20px;
        letter-spacing: 0.11px;
        line-height: 24px;
        text-align: center;
    }
    .reliable-wrap {
        padding: 40px 0 60px;
    }
    .contact-wrap .gray-box {
        padding: 30px 15px; 
    }
    .contact-wrap .main-heading {
        font-size: 28px;
        max-width: 280px;
        margin: 0 auto;
        line-height: 34px;
    }
    .contact-wrap .md-title.pb-5 {
        padding-bottom: 1.5rem !important;
    }
    .service-banner .green-btn.lg-btn,
    .service-member-wrap .green-btn.lg-btn,
    .contact-wrap .green-btn.lg-btn,
    .drop-line-wrap .green-btn.lg-btn,
    .service-banner .transparent-green-btn.lg-btn {
        font-size: 1.6em !important;
        padding: 10px 45px !important;
    }
    .service-member-wrap .become-member-pos {
        padding-left:20px;
        padding-right:20px;
    }
    .service-member-wrap .main-heading {
        max-width: 310px;
        margin: 0 auto 25px;
    }
    .contact-wrap .mt-4.text-end {
        text-align: center !important;
    }
    .contact-wrap .green-btn.lg-btn,
    .drop-line-wrap .green-btn.lg-btn {
        width: 100% !important;
    }
    .about-wrap {
        padding: 50px 0;
    }
    .about-first-block {
        text-align: center;
    }
    .about-first-block .main-heading {
        font-weight: 900;
    }
    .cs-content p {
        font-size: 16px;
        letter-spacing: -0.32px;
        line-height: 24px;
        text-align: center;
    }
    .meet-team-block .main-heading {
        font-size: 18px !important;
        font-weight: bold;
        letter-spacing: 0.09px;
        line-height: 32px;
        text-align: center;
        text-transform: uppercase;
        padding: 0 !important;
        margin-bottom: 1.5rem !important;
    }
    .meet-team-box .mnh-340 {
        min-height: inherit !important;
    }
    .meet-team-box p {
        font-size: 14.24px;
        letter-spacing: -0.09px;
        line-height: 25.13px;
        text-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
    }
    .meet-team-box {
        padding: 20px 25px 30px;
    }
    .meet-team-block .g-5 {
        --bs-gutter-y: 1.5rem;
    }
    .drop-line-wrap .main-heading {
        font-size: 28px;
        font-weight: 900;
        letter-spacing: -0.56px;
        line-height: 34px;
        text-align: center;
    }
    .drop-line-wrap .service-from {
        padding: 0 15px;
    }
    .service-from textarea.form-control {
        height: 140px !important;
    }
    .contact-banner {
        padding: 30px 0 100px;
    }
    .contact-banner .main-heading {
        font-size: 28px;
        font-weight: 900;
        letter-spacing: -0.56px;
        line-height: 34px;
        text-align: center;
    }
    .contact-flex {
        margin-top: 30px;
    }
    .ferrari-logo-mix {
        width: 100%;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 1.5rem !important;
    }
    .ferrari-logo-mix img:first-child {
        max-height: 68px;
    }
    .ferrari-logo-mix img:last-child {
        max-height: 52px;
    }
    .about-service-wrap .pe-5 {
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
    }
    .about-service-wrap .main-heading {
        line-height: normal;
        font-size: 22px !important;
    }
    .about-service-wrap .main-heading br {
        display: none;
    }
    .about-service-wrap .content p {
        font-size: 1.1em;
        letter-spacing: -0.32px;
        line-height: 22px;
    }
    .about-service-wrap .content p + p {
        margin-top: 14px;
    }
    .vdpa-info-box {
        margin-top: 50px;
    }
    .car-modal-box {
        height: 140px;
    }
    .car-modal-img.mb-4 {
        margin-bottom: 0.7rem !important;
        margin-top: 0 !important;
    }
    .vdpa-box-service {
        overflow-x: auto;
    }
    .vdpa-box-service .vintage-table {
        width: 100%;
    }
    .vdpa-box-service .vt-header {
        padding-right: 10px;
    }
    .vintage-table .scroll-y {
        overflow-x: hidden;
    }
    .mob-lg-btn {
        font-size: 1.6em !important;
        padding: 10px 45px !important;
    }
    .btns-group {
        padding: 20px 0;
    }
    .btns-group .d-flex {
        display: block !important;
    }
    .btns-group .d-flex>div {
        padding: 10px 0;
    }
    .btns-group .green-btn {
        width: 100%;
    }
    .service-perks-wrap .main-heading,
    .vintage-services-wrap .main-heading {
        margin-bottom: 1.5rem !important;
        font-size: 24px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 29px;
    }
    .car-modals-wrap {
        padding-top: 1.5rem !important;
    }
    .car-modals-wrap .md-title {
        margin-bottom: 1.5rem !important;
    }
    .car-modals-wrap .g-4 {
        --bs-gutter-x: 1.1rem;
        --bs-gutter-y: 1.1rem;
    }
    .car-modals-wrap .mt-5.pt-3 {
        margin-top: 1rem !important;
    }
    .address-direction-wrap .mobile-app-form {
        flex-wrap: wrap;
    }
    .address-direction-wrap .mobile-app-form .form-control {
        padding: 14px 22px;
        margin-right: 0 !important;
    }
    .address-direction-wrap .mobile-app-form .green-btn {
        font-size: 20px;
        padding: 14px 22px !important;
    }
    .ferrari-logo img {
        max-height: 72px;
    }
    .vintage-table tr th,
    .vintage-table tr td {
        padding: 12px 24px;
    }
    .font-1-5em {
        font-size: 1.2em !important;
    }
    .com-commmon-wrap {
        padding: 30px 0;
    }
    .com-common-box {
        padding: 40px 17px;
    }
    .lg-title {
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0.11px;
        line-height: 32px
    }
    .lg-title.mb-4 {
        margin-bottom: 0.7rem !important;
    }
    .com-commmon-wrap .content p {
        font-size: 16px;
        letter-spacing: -0.32px;
        line-height: 22px;
        font-weight: 400;
    }
    .community-content .content p {
        font-size: 16px;
        letter-spacing: -0.32px;
        line-height: 22px;
        font-weight: 400;
        text-align: center;
    }
    .community-content .mt-5 {
        margin-top: 2rem !important;
    }
    .community-content .green-btn {
        width: 100% !important
    }
    .drop-line-wrap .mx-50 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .drop-line-wrap .px-50 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .community-content .content {
        padding: 20px 0 0;
    }
    .horsepower-wrap {
        padding: 20px 0 40px;
    }
    .horsepower-block {
        font-size: 2em;
    }
    .horsepower-block img {
        max-width: 70px;
    }
    .horsepower-block > div {
        position: absolute;
        padding: 0 20px;
    }
    .horsepower-block > div br {
        display: none;
    }
    .community-banner-wrap .banner-flex {
        height: 210px;
    }
    .menu-block {
        width: 100% !important;
    }
    .community-banner-wrap {
        background-position: center center !important;
        background-size: cover !important;
    }
    .drop-line-wrap {
        background-attachment: inherit !important;
    }
    .up-arrow-icon {
        transform: rotate(90deg);
        transition: all 0.5s ease-in-out 0s;
        animation: jumpInfinite 1.5s infinite;
        position: absolute;
        top: 0;
    }
    .la-title {
        color: #F75050;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: -0.1px;
        line-height: 24px;
        text-transform: uppercase;
    }
    .rb-count {
        font-size: 11px;
    }
    .register-to-bid-block {
        border-radius: 16px 16px 0 0;
        background-color: #212020;
        box-shadow: 0 -5px 8px 0 rgba(0,0,0,0.85);
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .rb-img img {
        max-width: 88px;
    }
    .register-to-bid-block .vdp-md-title {
        font-size: 14px !important;
        font-weight: bold;
        letter-spacing: -0.1px;
        line-height: 16px;
        text-align: left;
    }
    .register-to-bid-block .rb-xs-title span {
        display: none;
    }
    .register-to-bid-block .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .register-to-bid-block .d-flex.mt-2.mb-3 {
        margin-bottom: 0 !important;
    }
    .register-to-bid-block .d-flex {
        align-items: center;
    }
    .register-to-bid-block .row > div {
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
    }
    .rb-slider .item {
        padding-top: 30px;
    }
    .mob-rb-count {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .la-main-block {
        position: absolute;
        left: 0;
        width: 100%;
        top: 15px;
        padding-left: 15px;
        z-index: 10;
    }
    .register-bid-btn {
        padding: 6px 35px !important;
        margin-right: 2px;
    }
    .la-main-block .d-flex>div {
        width: 33.33%;
    }
    .la-main-block .d-flex>div:nth-child(2) {
        text-align: center;
    }
    .la-main-block .rb-left-icon {
        vertical-align: -4px;
    }
    .register-to-bid-block .mob-bid-info {
        height: 0px;
        visibility: hidden;
        display: block !important;
        transition: all 1s ease-in-out 0s;
        padding-top: 0 !important;
    }
    .register-to-bid-block.open .mob-bid-info {
        height: 47px;
        visibility: visible;
        transition: all 1s ease-in-out 0s;
        padding-top: 10px !important;
    }
    .rb-slider .owl-dots {
        display: block;
    }
    .register-to-bid-block .rb-slider .owl-dots {
        height: 0px;
        display: block;
        visibility: hidden;
        margin-top: 0px;
        transition: all 1s ease-in-out 0s;
    }
    .register-to-bid-block.open .rb-slider .owl-dots {
        display: block;
        text-align: center;
        margin-top: 7px;
        height: 21px;
        visibility: visible;
        transition: all 1s ease-in-out 0s;
    }
    .rb-slider .owl-dots .owl-dot {
        height: 8px;
        width: 8px;
        opacity: 0.25;
        background-color: #FFFFFF;
        margin: 0 5px;
        display: inline-block;
        border-radius: 10px;
    }
    .rb-slider .owl-dots .owl-dot.active {
        opacity: 1;
    }
    .rb-img {
        width: 87px;
    }
    .register-to-bid-block .up-arrow-icon {
        transition: all 0.3s ease-in-out 0s;
    }
    .register-to-bid-block.open .up-arrow-icon {
        transform: rotate(270deg);
    }
    .lat-left {
        border-right: none !important;
        width: 100%;
        padding: 0 !important;
    }
    .lat-left>div:last-child {
        border-left: 1px solid rgb(151 151 151 / 40%);
        padding-left: 15px;
    }
    .lat-left .me-4 {
        margin-right: 0.7rem !important;
    }
    .la-tagline-box {
        padding: 10px 15px;
        margin-bottom: 20px !important;
    }
    .la-list-box {
        border: none;
        margin-bottom: 20px;
    }
    .mb-100 {
        margin-bottom: 60px;
    }
    .la-bottom>div:last-child {
        position: relative;
    }
    .off-retail-text {
        position: absolute;
        right: 0;
        top: -50px;
        margin-bottom: 0 !important;
        line-height: 22px;
    }
    .la-banner-wrap.py-5 {
        padding-bottom: 1rem !important;
    }
    .cc_info .card {
        color: #333;
    }
    /* .banner-wrap video {
        width: auto;
        height: 610px;
    } */
    .bid-modal-body .text-black {
        color: #fff !important;
    }
    .bid-modal-body .tab-content .text-black {
        color: #333 !important;
    }
    .bid-modal-body .col-md-6 {
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
    }
    .bid-modal-body .border-l {
        border-left: none;
    }
    .vehicle-options tr th,
    .vehicle-options tr td {
        color: #fff;
        padding: 5px 0;
    }
    .bid-modal-body .tab-pane .vehicle-options tr th,
    .bid-modal-body .tab-pane .vehicle-options tr td {
        color: #333;
        padding: 5px 0;
    }
    .live-auction-flex {
        display: block !important;
    }
    .live-auction-flex .w-50 {
        width: 100% !important;
    }
    .reverse {
        color: #333;
    }
    .bid-modal-body .nav-tabs .nav-item {
        margin: 0 5px;
    }
    .pro-box .pro-content {
        position: relative;
        transform-style: inherit;
    }
    .pro-box .before-hover {
        display: none;
    }
    .pro-box .after-hover {
        width: 100%;
        margin-left: inherit;
        height: auto;
        margin-top: inherit;
        transform: inherit;
        position: relative;
        left: inherit;
        top: inherit;
        backface-visibility: inherit;
    }
    .pro-bottom .green-btn {
        padding: 9px 15px !important;
        display: inline-block;
    }
    .srp-actions-flex {
        flex-wrap: wrap;
        text-align: center;
    }
    .pro-box:hover {
        z-index: 0;
    }
    .pro-box:hover .pro-content {
        transform: inherit;
        z-index: 0;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
    .product-list .col-md-4:hover, 
    .product-list .col-md-8:hover {
        z-index: 0;
    }
    
    .register-to-bid-block .col-lg-8.col-xl-6 > .d-flex {
        width: 100%;
    }
    .rb-slider .ps-4 {
        width: calc(100% - 90px);
    }
    .go-right .mobile-app-form {
        display: block !important;
    }
    .grand-opening-wrap .mobile-app-form .form-control {
        padding: 15px 20px 14px !important;
        width: 100%;
    }
    .grand-opening-wrap .green-btn {
        padding: 15px 15px !important;
    }
    .grand-opening-wrap .sub-heading {
        font-size: 1.3em;
    }
    .grand-opening-wrap .main-heading {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 40px;
        max-width: 335px;
        margin: 0 auto;
    }
    .grand-opening-wrap {
        background: url(../img/mob-grand-opening-bg.webp) no-repeat center center;
        background-size: cover;
        background-attachment: inherit;
    }
    .go-left img {
        max-width: 285px;
    }
    .grand-opening-flex {
        padding: 40px 0 60px;
    }
    .banner-wrap .w-55 {
        max-width: 282px;
        margin: 0 auto 55px;
    }
    .m-title1 {
        left: -5px;
    }
    .m-title1 img {
        max-height: 35px;
    }
    .m-title2 {
        top: 55px;
        left: 63px;
    }
    .m-title2 img {
        max-height: 44px;
    }
    .m-title3 {
        top: 40px;
        left: 191px;
    }
    .m-title3 img {
        max-height: 34px;
    }
    .h-225 {
        height: 146px;
    }
    .home-banner-wrap video {
        height: 300px;
    }    
    .home-banner-wrap {
        height: 570px;
        position: relative;
    }
    .video-relative {
        position: relative;
    }
    .video-relative:after {
        content: '';
        position: absolute;
        bottom: 0;
        background: rgb(0,0,0);
        background: linear-gradient(to bottom, rgba(40,40,40,0) 0%,rgba(40,40,40,1) 100%);
        height: 70px;
        left: 0;
        right: 0;
        z-index: 2;
    }
    .home-banner-wrap video {
        position: relative;
        top: inherit;
        left: inherit;
    }
    .dark-theme-modal .gom-content .modal-body {
        background: inherit;
        padding: 30px 15px !important;
    }
    .grand-open-img img {
        max-width: 310px;
    }
    .dark-theme-modal .gom-content .main-heading {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: -0.19px;
        line-height: 36px;
        text-shadow: 0 8px 4px 0 rgba(0,0,0,0.64);
        max-width: 345px;
        margin-left: auto;
        margin-right: auto;
    }
    .dark-theme-modal .gom-content .main-heading br {
        display: none;
    }
    .dark-theme-modal .gom-content .sm-box-close {
        position: absolute;
        right: 15px;
    }
    .join-mailing-btn {
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 24px;
        padding: 10px 30px 8px;
    }
    .gom-bottom .d-flex {
        display: block !important;
        text-align: left;
        padding: 0 !important;
    }
    .mt-206 {
        margin-top: 240px;
    }
    .gom-bottom {
        padding: 0 20px;
    }
    .gom-bottom .d-inline-flex {
        display: flex !important;
        justify-content: space-between;
    }
    .go-label {
        margin-bottom: 7px;
    }
    .grand-open-img {
        margin-top: 30px;
    }
    .banner-wrap {
        height: auto;
    }
    .home-banner {
        padding-bottom: 0px !important;
    }
    .scroll-down-btn {
        display: none;
    }
    .xxl-title {
        font-size: 2.8em;
    }
    .xl-title {
        font-size: 1.3em;
        line-height: 1.5em;
    }
    .xl-title.my-4 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .xl-title.my-4 + .mt-5 {
        margin-top: 1.5rem !important;
    }
    .xl-btn {
        padding: 14px 55px !important;
    }
    .panel-inside-bg.d-flex {
        display: block !important;
        min-height: inherit;
    }
    .mxw-470 {
        max-width: 100%;
        padding: 60px 20px;
    }
    .panel-inside-bg .w-50 {
        width: 100% !important;
    }
    .img-100vh img {
        width: 100%;
        height: inherit;
    }
    .panel-inner {
        height: auto;
        min-height: 80vh;
    }
    
    .sales-main-block {
        background: url(../img/mob-sales-full-bg.webp) no-repeat center center;
        background-size: 100% 100% !important;
        height: 500px;
    }
    .custom-accordion .accordion-button {
        padding: 20px 30px 0;
        font-size: 17px;
    }
    .custom-accordion .accordion-button.collapsed {
        padding: 20px 30px;
    }
    .custom-accordion .accordion-body {
        padding: 15px 30px 20px;
    }
    .recently-vehicles-wrap {
        padding: 50px 0;
    }
    .custom-accordion .accordion-button::after {
        width: 28px;
        height: 28px;
    }
    .eca-wrap > img {
        height: 285px;
        object-fit: cover;
    }
    .eca-title1 {
        font-size: 2.2em;
        padding: 9px 26px;
    }
    .eca-title2 {
        font-size: 1.2em;
        margin-top: 0.5rem !important;
        padding: 10px 26px;
    }
    .eca-pos {
        top: 25px;
    }
    .eca-get-offer {
        margin: 25px auto 0;
        padding: 25px 20px;
    }
    .eca-get-offer .form-control {
        padding: 13px 20px 13px 20px;
    }
    .eca-get-offer .lg-btn,
    .get-instent-form .form-group .lg-btn {
        padding: 9px 15px !important;
        font-size: 1.5em !important;
    }
    .eca-get-offer .md-title.mt-5 {
        margin-top: 2rem !important;
    }
    .eca-get-offer .md-title.mt-5 img {
        max-width: 17px;
        vertical-align: -4px;
    }
    .how-works-wrap {
        padding-top: 220px !important;
    }
    .how-works-wrap .md-title {
        font-size: 2em !important;
        text-align: center ;
    }
    .hw-circle {
        width: 54px;
        min-width: 54px;
        height: 54px;
        line-height: 52px;
    }
    .hw-left {
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
    }
    .hw-left > .d-flex + .d-flex {
        margin-top: 35px;
    }
    .hwr-icon {
        min-width: 35px;
        max-width: 35px;
        text-align: center;
    }
    .hwr-icon img {
        max-height: 43px;
    }
    .hwr-title {
        font-size: 1.2em;
    }
    .hwr-box {
        height: 80px;
    }
    .hw-right .g-5 {
        --bs-gutter-y: 1.2rem;
        --bs-gutter-x: 1.5rem;
    }
    .get-offer-block {
        display: block !important;
        text-align: center;
    }
    .get-offer-block .md-title {
        font-size: 1.5em !important;
        line-height: 1.5em;
    }
    .get-offer-block .w-50 {
        width: 100% !important;
        margin-top: 20px;
    }
    .get-offer-block .green-btn {
        padding: 13px 25px !important;
        font-size: 1.8em !important;
        max-width: 300px;
    }
    .eca-count-box {
        padding: 0 25px;
        border-radius: 0;
        background-color: transparent;
    }
    .how-works-wrap {
        padding-bottom: 40px !important;
    }
    .recently-vehicles-wrap .reliable-title {
        font-size: 1.9em;
        text-align: center !important;
        line-height: 1.4em;
    }
    .recently-vehicles-wrap {
        padding-bottom: 0;
    }
    .eca-logos {
        padding: 20px 0;
    }
    .get-offer-wrap {
        padding: 30px 0;
    }
    .get-offer-form .green-btn {
        width: 130px !important;
        padding: 6px 25px !important
    }
    .get-offer-form .form-group:first-child {
        width: calc(100% - 130px);
    }
    .get-offer-wrap .font-1-8em {
        font-size: 1.3em !important;
    }
    .faqs-wrap {
        padding: 50px 0;
    }
    .faqs-wrap .md-title {
        text-align: center;
        max-width: 335px;
        margin: 0 auto;
    }
    .custom-accordion .accordion-button {
        padding: 20px 20px 0;
        font-size: 16px;
    }
    .custom-accordion .accordion-body {
        padding: 15px 20px 20px;
    }
    .custom-accordion .accordion-button.collapsed {
        padding: 20px 20px;
    }
    .get-instant-box .md-title {
        font-size: 1.7em !important;
        max-width: 295px;
        margin: 0 auto;
    }
    .get-instent-form .form-group .form-control {
        padding: 18px 25px;
        width: 100%;
    }
    .get-instant-box .green-btn {
        padding: 16px 25px !important;
        font-size: 1.5em !important;
        width: 100% !important;
    }
    .get-instant-box {
        padding: 38px 20px;
    }
    .eca-count-wrap .col-lg-3 {
        border-bottom: solid 1px rgb(197 199 202 / 30%);
        padding-bottom: 20px !important;
        margin-bottom: 20px;
    }
    .eca-count-wrap .col-lg-3:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
    .recently-vehicles-wrap .row {
        margin-right: calc(-.25* var(--bs-gutter-x));
        margin-left: calc(-.25* var(--bs-gutter-x));
    }
    .recently-vehicles-wrap .col-md-6 { 
        padding-right: calc(var(--bs-gutter-x)* .25);
        padding-left: calc(var(--bs-gutter-x)* .25);
    }
    .appraise-car-box {
        padding-left: 20px;
        padding-right: 20px;
    }
    .custom-modal .appraise-car-modal .modal-body {
        min-height: calc(100vh - 125px);
    }
    .custom-modal .modal-footer {
        padding: 13px 10px;
    }
    .panel-banner-wrap .banner-wrap {
        min-height: calc(100vh - 65px);
        height: auto;
    }
    .video-background {
        height: calc(100vh - 65px);
        padding-top: 0;
        object-fit: cover;
    }
    .panel-banner-wrap .home-banner-pos {
        top: 44%;
    }
    .get-instent-form .form-group:first-child {
        margin-right: 0;
    }
    .eca-wrap {
        height: 284px;
        overflow: inherit;
        background-attachment: inherit;
        background-position: center center;
    }
    .pro-full-width {
        min-height: inherit;
    }
    .pro-box .after-hover,
    .pro-box .after-hover {
        display: block !important;
        padding: 0;
    }
    .pro-full-width .pro-top {
        min-width: inherit;
        width: 100%;
    }
    .pro-full-width .pro-bottom {
        width: 100%;
    }
    .pro-full-width .pro-top img {
        border-radius: 20px 20px 0 0;
    }
}
@media (max-width: 576px) {
    .after-hover .pro-bottom .mt-5 {
        margin-top: 1.5rem !important;
    }
    .after-hover .pro-bottom>.d-flex:first-child,
    .after-hover .pro-bottom>.d-flex:nth-child(2) {
        display: block !important;
        margin-bottom: 0 !important;
    }
    .after-hover .pro-bottom>.d-flex>.d-flex {
        width: 100% !important;
        margin-bottom: 7px;
    }
    .after-hover .pro-bottom>.d-flex>.d-flex>div:first-child {
        width: 45% !important;
    }
    .after-hover .pro-bottom>.d-flex>.d-flex>div:last-child {
        width: 55% !important;
    }
    .flip-back-btn {
        color: #79BB30;
        font-family: "Nunito Sans";
        font-size: 14px;
        letter-spacing: -0.28px;
        line-height: 16px;
        border: none;
        background: transparent;
    }
    .flip-back-btn img {
        vertical-align: -2px;
        margin-right: 10px;
    }
    .perk-box {
        height: 98px;
        padding: 14px;
    }
    .perk-icon {
        text-align: center;
    }
    .breadcrumb-flex {
        flex-wrap: wrap;
        margin-bottom: 1.5rem !important;
    }
    .breadcrumb-flex > div:last-child {
        width: 100%;
        padding-top: 10px;
    }
    .lal-img img {
        height: 170px;
    }
    .grand-opening-wrap .sub-heading br {
        display: none;
    }
    .after-hover .pro-bottom>.d-flex>.d-flex>div:first-child {
        width: 38% !important;
    }
    .after-hover .pro-bottom>.d-flex>.d-flex>div:last-child {
        width: 62% !important;
    }
    .pro-full-width .lt-main-title {
        font-size: 1.5em;
        margin-bottom: 0.5rem !important;
    }
    .pro-full-width .sm-title {
        font-size: 1.2em !important;
        margin-bottom: 0 !important;
    }
    .pro-full-width .mt-5.pt-2 .d-flex .xs-title {
        padding-right: 4px !important;
    }
    .pro-full-width .mt-5.pt-2 .xs-title, 
    .pro-full-width .mt-5.pt-2 .sm-title {
        font-size: 1.1em !important;
    }
    .doc_icon {
        margin-right: 3px;
        vertical-align: -4px;
    }
    .pro-full-width .pro-top img {
        height: 208px;
    }
    .vdpa-box-service .vintage-table {
        width: 100%;
    }
    .vintage-table .scroll-y {
        overflow-x: inherit;
        max-height: inherit;
        overflow-y: inherit;
    }
    .vdpa-box-service .vintage-table tr th:first-child,
    .vdpa-box-service .vintage-table tr td:first-child {
        min-width: 130px !important;
    }
    .vdpa-box-service .vintage-table tr th,
    .vdpa-box-service .vintage-table tr td {
        min-width: 80px !important;
        padding: 12px 10px;
    }
    .rpv-box {
        padding: 11px;
        border-radius: 13px;
    }
    .rpv-box .sm-title {
        font-size: 12px !important;
    }
    .rpv-img img {
        border-radius: 6px;
    }
    .rpv-content {
        padding-top: 11px;
    }
    .rpv-content .mb-3 {
        margin-bottom: 0.20rem !important;
    }
    .rpv-address {
        font-size: 10px;
    }
    .recently-vehicles-wrap .reliable-title {
        margin-bottom: 20px !important;
    }
}
@media (max-width: 480px) {
    .login-btn .w-50,
    .register-btn .w-50 {
        width: 100% !important;
    }
    .about-action-flex {
        display: block !important;
        text-align: center;
        margin-top: 0.5rem !important;
    }
    .about-action-flex .md-title {
        margin-bottom: 1rem;
    }
    .md-title.my-5.pt-5.pb-3 {
        margin-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .service-banner .green-btn.lg-btn,
    .become-member-pos .green-btn.lg-btn,
    .contact-wrap .green-btn.lg-btn,
    .drop-line-wrap .green-btn.lg-btn,
    .service-banner .transparent-green-btn.lg-btn {
        width: 100% !important;
    }
    .car-modals-wrap .lg-btn {
        width: 100%;
    }
    .py-100 {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }
    .featured-slider .slick-prev {
        width: 45px;
        height: 45px;
        left: 5px;
    }
    .featured-slider .slick-next {
        width: 45px;
        height: 45px;
        right: 5px;
    }
    .featured-slider .slick-prev, 
    .featured-slider .slick-next {
        top: calc(50% - 20px);
    }
    .panel-banner-wrap .home-banner {
        padding-bottom: 30px !important;
    }
    .banner-box {
        padding: 18px 25px;
    }
    .eca-get-offer {
        width: 100%;
    }
    .sold-btn {
        padding: 2px 5px 1px 5px;
        border-radius: 13px;
        font-size: 9px;
        width: 52px;
    }
}
@media (max-width: 420px) {
    .vdpa-table tr td.text-nowrap {
        font-size: 11px;
    }
    .vdpa-box-service .vintage-table tr th,
    .vdpa-box-service .vintage-table tr td {
        padding: 12px 5px;
    }
    .vdpa-box-service .vintage-table tr th:first-child,
    .vdpa-box-service .vintage-table tr td:first-child {
        min-width: 80px !important;
    }
    .vintage-table tr td:first-child {
        font-size: 0.9em;
    }
    .vintage-table tr td {
        font-size: 0.9em;
    }
    .vdpa-box-service .vintage-table tr th, 
    .vdpa-box-service .vintage-table tr td {
        min-width: 50px !important;
    }
    .pm-logos img {
        max-width: 100%;
    }
    .mnw-195 {
        min-width: 105px;
    }
}
@media (max-width: 380px) {
    .horsepower-block > div {
        padding: 0;
    }
    .mp-box {
        height: 100px;
        width: 100px;
        padding-top: 1.2rem !important;
    }
    .view-sticker-btn {
        font-size: 0.8em;
    }
    
}
@media (max-width: 350px) {
    .mp-box {
        height: 90px;
        width: 90px;
    }
    .mp-box .mp-title {
        font-size: 0.9em;
    }
    .pro-bottom .green-btn {
        padding: 9px 10px !important;
    }
    .grand-open-img img {
        max-width: 280px;
    }
}