
.message-area {
    height: auto;
    overflow: hidden;
    padding: 10px 0;
    background: #31313D;
    border-radius: 0 0 4px 4px;
}

.chat-area {
    position: relative;
    width: 100%;
    border-radius: 5px;
    background-color: #23242D;
    height: 90vh;
    overflow: hidden;
    min-height: calc(100% - 1rem);
}

.chatlist {
    outline: 0;
    height: 100%;
    overflow: hidden;
    width: 376px;
    float: left;
    padding: 15px;
}

.chat-area .modal-content {
    border: none;
    border-radius: 0;
    outline: 0;
    height: 100%;
    background: transparent;
}

.chat-area .chat-dialog-scrollable {
    height: 100% !important;
}

.chatbox {
    width: auto;
    overflow: hidden;
    height: 100%;
    border-left: 2px solid #18191A;
}

.chatbox .modal-dialog,
.chatlist .modal-dialog {
    max-width: 100%;
    margin: 0;
}

.msg-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chat-area .form-control {
    display: block;
    width: 80%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}
.search-input {
    width: calc(100% - 62px);
}
.msg-search .form-control {
    border: 1px solid #CCCCCC;
    border-radius: 20px;
    background-color: #FFFFFF;
    color: #333;
    
    font-size: 12px;
    letter-spacing: -0.07px;
    line-height: 13px;
    height: 40px;
    width: 100%;
    padding-left: 40px;
}
.msg-search .form-control::placeholder {
    opacity: 0.6;
}
.msg-search .form-control::-ms-input-placeholder { 
    opacity: 0.6;
}
.msg-search .add {
    border: none;
    background: transparent;
    padding: 0;
    margin-left: 12px;
    width: 60px;
    height: 50px;
}
.chat-area .form-control:focus {
    outline: 0;
    box-shadow: inherit;
}

a.add img {
    height: 36px;
}

.chat-area .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    align-items: center;
    justify-content: space-between;
    flex-wrap: inherit;
}

.chat-area .nav-tabs .nav-item {
    width: 100%;
}

.chat-area .nav-tabs .nav-link {
    width: 100%;
    color: #180660;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.chat-area .nav-tabs .nav-item.show .nav-link,
.chat-area .nav-tabs .nav-link.active {
    color: #222;
    background-color: #fff;
    border-color: transparent transparent #000;
}

.chat-area .nav-tabs .nav-link:focus,
.chat-area .nav-tabs .nav-link:hover {
    border-color: transparent transparent #000;
    isolation: isolate;
}

.chat-list h3 {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: -0.1px;
    line-height: 19px;
    text-transform: capitalize;
    margin-bottom: 4px;
    text-align: left;
}

.chat-list p {
    color: #7B7B7B;
    
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.07px;
    line-height: 15px;
    text-transform: capitalize;
    margin-bottom: 0;
    text-align: left;
}

.chat-list button.d-flex {
    margin-bottom: 0;
    position: relative;
    text-decoration: none;
    background-color: #23262B;
    border: none;
    border-bottom: 2px solid #18191A;
    padding: 15px;
}
.chat-list button.d-flex .flex-shrink-0 {
    height: 51px;
    width: 51px;
    border: 1px solid #474A4D;
    background-color: #18191A;
    border-radius: 50%;
    position: relative;
}
.chat-list button.d-flex .img-fluid {
    height: 51px;
    width: 51px;
}
.user-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.chat-list .active {
    display: block;
    content: '';
    clear: both;
    position: absolute;
    bottom: 3px;
    left: 34px;
    height: 12px;
    width: 12px;
    background: #00DB75;
    border-radius: 50%;
    border: 2px solid #fff;
}

.msg-head h3 {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
}

.msg-head p {
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.msg-head {
    padding: 15px 20px;
    border-bottom: none;
    border-radius: 0 5px 0 0;
    background-color: #1D1F22;
}

.moreoption {
    display: flex;
    align-items: center;
    justify-content: end;
}

.moreoption .navbar {
    padding: 0;
}

.moreoption li .nav-link {
    color: #222;
    font-size: 16px;
}

.moreoption .dropdown-toggle::after {
    display: none;
}

.moreoption .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: auto;
    right: 0;
    margin-top: 0.125rem;
}

.msg-body {
    padding: 20px 92px 50px;
}
.msg-body ul li:last-child {
    margin-bottom: 0;
}
.msg-body ul li {
    list-style: none;
    margin: 0 0 30px;
}

.msg-body ul li.sender {
    display: block;
    width: 100%;
    position: relative;
    transform: translateZ(0);
}

.msg-body ul li.sender .msg-box:before {
    display: block;
    clear: both;
    content: '';
    position: absolute;
    bottom: 0px;
    left: -19px;
    background-color: #2e3134;
    width: 20px;
    height: 20px;
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
}
.msg-body ul li.sender .msg-box {
    padding: 25px 40px;
    border-radius: 10px 10px 10px 0;
    background-color: #2E3134;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
}
.msg-body ul li p {
    color: #FFFFFF;
    
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 24px;
}


.msg-body ul li.repaly {
    display: block;
    width: 100%;
    position: relative;
}
.msg-body ul li.repaly .msg-box {
    padding: 25px 55px;
    border-radius: 10px 10px 0 10px;
    background-color: #474A4D;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
    position: relative;
}

.msg-body ul li.repaly .msg-box:before {
    display: block;
    clear: both;
    content: '';
    position: absolute;
    bottom: 0px;
    right: -19px;
    background-color: #474A4D;
    width: 20px;
    height: 20px;
    clip-path: polygon(0% 100%, 0% 0%, 100% 100%);
}


.time {
    color: #FFFFFF;
    
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.07px;
    line-height: 15px;
    text-align: right;
    position: absolute;
    right: 30px;
    bottom: 10px;
}

.divider {
    position: relative;
    z-index: 1;
    text-align: center;
}

.msg-body h6 {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #222;
    background: #fff;
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 0;
}

.divider:after {
    display: block;
    content: '';
    clear: both;
    position: absolute;
    top: 12px;
    left: 0;
    border-top: 1px solid #EBEBEB;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.send-box {
    padding: 20px;
    border-radius: 0 0 5px 0;
    background-color: #474A4D;
}

.send-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}

.send-box .form-control {
    display: block;
    width: calc(100% - 205px);
    padding: 0.375rem 0.75rem;
    color: #9B9B9B;
    
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 19px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    background-color: #FFFFFF;
    height: 40px;
}
.send-box .form-control::placeholder {
    opacity: 0.6;
}
.send-box .form-control::-ms-input-placeholder { 
    opacity: 0.6;
}
button.chat-theme-btn,
a.chat-theme-btn {
    border: none;
    border-radius: 5px;
    background-color: #79BB30;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.09px;
    line-height: 16px;
    text-align: center;
    width: 130px;
    height: 40px;
    text-transform: uppercase;
    margin-left: 15px;
}

a.chat-theme-btn {
    line-height: 40px;
    text-decoration: none;
}
.send-box button i {
    margin-right: 5px;
}

.send-btns .button-wrapper {
    position: relative;
    width: 125px;
    height: auto;
    text-align: left;
    margin: 0 auto;
    display: block;
    background: #F6F7FA;
    border-radius: 3px;
    padding: 5px 15px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    overflow: hidden;
}

.send-btns .button-wrapper span.label {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    color: #343945;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 13px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.send-btns .attach .form-control {
    display: inline-block;
    width: 120px;
    height: auto;
    padding: 5px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #343945;
    background-color: #F6F7FA;
    background-clip: padding-box;
    border: 1px solid #F6F7FA;
    border-radius: 3px;
    margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
    margin-right: 5px;
}

.button-wrapper {
    position: relative;
    width: 50px;
    height: 35px;
    text-align: center;
    margin: 0 0 0 15px;
    padding: 3px 0;
    overflow: hidden;
}

button:focus {
    outline: 0;
}

.add-apoint {
    display: inline-block;
    margin-left: 5px;
}

.add-apoint a {
    text-decoration: none;
    background: #F6F7FA;
    border-radius: 8px;
    padding: 8px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    color: #343945;
}

.add-apoint a svg {
    margin-right: 5px;
}

.chat-icon {
    display: none;
}

.closess i {
    display: none;
}
.chat-list button.d-flex.open {
    background-color: #2E3134;
}
.status-circle {
    height: 6px;
    width: 6px;
    background-color: #898F95;
    border-radius: 6px;
    margin-left: 7px;
    display: inline-block;
}
.status-circle.green {
    background-color: #79BB30;
}
.status-circle.gray {
    background-color: #898F95;
}
.status-circle.yellow {
    background-color: #E8C71E;
}
.status-circle.red {
    background-color: #D84353;
}
.unread-count {
    height: 20px;
    width: 20px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
    background: #DA4656;
    color: #FFFFFF;
    
    font-size: 10px;
    letter-spacing: -0.06px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
}
.chat-time {
    color: #FFFFFF;
    
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.07px;
    line-height: 15px;
    text-align: right;
}
.chat-header {
    background: #23242D;
    padding: 25px;
    display: block;
    align-items: center;
}
.chat-xs-title {
    color: #7B7B7B;
    
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.07px;
    line-height: 15px;
}
.chat-sm-title {
    color: #7B7B7B;
    
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 19px;
}
.chat-search-icon {
    background: url(../img/icon-search-chat.svg) no-repeat center center;
    background-size: 100%;
    width: 22px;
    height: 22px;
    border: none;
    padding: 0;
}
.gray-search-icon {
    background: url(../img/gray-icon-search-chat.svg) no-repeat center center;
    background-size: 100%;
    width: 18px;
    height: 18px;
    border: none;
    padding: 0;
    display: inline-block;
    position: absolute;
    top: 11px;
    left: 12px;

}
.optionmenu-icon {
    background: url(../img/icon-options-chat.svg) no-repeat center center;
    background-size: 100%;
    width: 4.5px;
    height: 23px;
    border: none;
    padding: 0;
}

/* WebKit Scrollbar */
.chat-modal-body {
    overflow-y: scroll;
    flex-direction: column-reverse;
    overflow-anchor: auto !important;
    display: flex;
}
.chat-dialog-scrollable .modal-body::-webkit-scrollbar {
    width: 4px;
}
.chat-dialog-scrollable .modal-body::-webkit-scrollbar-track {
    background: transparent;
}
.chat-dialog-scrollable .modal-body::-webkit-scrollbar-thumb {
    background: rgb(216 216 216 / 30%);
    border-radius: 4px;
}
.msg-body ul li.chat-date {
    margin-bottom: 20px;
}
.chat-date-btn {
    border: 1px solid #333639;
    border-radius: 18px;
    background-color: #191A1C;
    color: #FFFFFF;
    
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 14px;
    text-align: center;
    display: inline-block;
    padding: 7px 28px;
}
.chat-dropdown .dropdown-menu {
    background-color: #3F404C;
    padding: 15px 0;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}
.chat-dropdown .dropdown-menu:before {
    content: "";
    position: absolute;
    right: 18px;
    top: -13px;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-bottom: solid 14px #3F404C;
}
.chat-dropdown .dropdown-item {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.09px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 7px 25px;
}
.chat-dropdown .dropdown-item:focus, 
.chat-dropdown .dropdown-item:hover {
    background-color: rgb(255 255 255 / 20%);
}
.chatmenu-dropdown .dropdown-menu {
 transform: translate(28px, 36px) !important;
}
.member-count {
    color: #757676;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;
}
.ch-xs-title {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;
}
.ch-xs-title:hover {
    color: #fff;
}
.chat-back-arrow {
    background: url(../img/back-arrow.svg) no-repeat center center;
    background-size: 100% auto;
    width: 10px;
    height: 17px;
    border: none;
    padding: 0;
    display: inline-block;
    vertical-align: -4px;
    margin-right: 10px !important;
}
.white-back-arrow {
    background: none;
    border: none;
}
.group-chat-box {
    top: -13px;
    position: relative;
}
.chat-ssm-title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.11px;
    line-height: 22px;
}
.chat-ssm-title span {
    font-size: 20px;
    color: #fff;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.chat-custom-form .form-control {
    font-size: 12px !important;
}
.sender-name {
    color: #fff;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: -0.1px;
    line-height: 19px;
    margin-bottom: 4px;
}
.chat-file-box {
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 17px 20px;
    width: 258px;
}
.chat-file-icon {
    background: url(../img/icon-doc.svg) no-repeat center center;
    width: 30px;
    height: 40px;
    background-size: 100%;
    margin-right: 15px;
}
.chat-download-icon {
    background: url(../img/icon-download-icon.svg) no-repeat center center;
    width: 30px;
    height: 30px;
    background-size: 23px 23px;
}
.chat-file-name {
    color: #121212;
    
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.08px;
    line-height: 18px;
}
.chat-file-info {
    color: #8C8C8C;
    
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.07px;
    line-height: 15px;
}
.msg-box a {
    color: #fff;
    
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 24px;
    text-decoration: none;
}

.character-text {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.11px;
    line-height: 27px;
    text-align: left;
    padding: 20px 25px 15px 25px;
}

.chat-search-box {
    background: #23242D;
    position: absolute;
    right: -400px;
    top: 0;
    bottom: 0;
    z-index: 11;
    width: 328px;
    border-left: 2px solid #18191A;
    transition: all 0.5s ease-in-out 0s;
}
.chat-search-box.open {
    right: 0px;
}
.chat-search-header {
    border-bottom: 2px solid #18191A;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.1px;
    line-height: 19px;
    padding: 16px 25px;
    text-transform: uppercase;
}
.chat-exit-icon {
    background: url(../img/exit.svg) no-repeat center center;
    background-size: 100% auto;
    width: 14px;
    height: 14px;
    border: none;
    padding: 0;
    display: inline-block;
    vertical-align: -2px;
    margin-right: 12px;
}
.chat-search-body {
    padding: 22px 28px;
}
.search-keyword-text {
    opacity: 0.6;
    color: #FFFFFF;
    
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 24px;
    text-align: center;
    margin-top: 30vh;
    font-weight: 500;
}

.sm-chats-block {
    display: none;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
}
.sm-chats-block.open {
    display: block;
}
.sm-chat-box {
    border: 1px solid #454444;
    border-radius: 5px 5px 0 0;
    background-color: #23242D;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
    width: 285px;
    margin-right: 7px;
}
.sm-chat-header {
    margin-bottom: 0;
    position: relative;
    text-decoration: none;
    background-color: #23262B;
    border: none;
    border-bottom: 2px solid #18191A;
    padding: 8px 15px;
    border-radius: 5px;
}
.sm-chat-header .flex-shrink-0 {
    height: 31px;
    width: 31px;
    border: 1px solid #474A4D;
    background-color: #18191A;
    border-radius: 50%;
    position: relative;
}
.sm-chat-header h3 {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: -0.1px;
    line-height: 19px;
    text-transform: capitalize;
    margin-bottom: 0;
    text-align: left;
}
.sm-chat-header .user-icon img {
    display: block;
}
.sm-chat-body {
    padding: 15px 18px;
    min-height: 240px;
    max-height: 240px;
    overflow-y: auto;
}
.sm-chat-body::-webkit-scrollbar {
    width: 4px;
}
.sm-chat-body::-webkit-scrollbar-track {
    background: transparent;
}
.sm-chat-body::-webkit-scrollbar-thumb {
    background: rgb(216 216 216 / 30%);
    border-radius: 4px;
}
.sm-chat-body .sender-name {
    font-size: 10px;
    margin-bottom: 0;
}
.sm-chat-body .time {
    font-size: 9px;
}
.sm-chat-body .msg-body ul li p {
    font-size: 12px;
    line-height: 18px;
}
.sm-chat-body .msg-body ul li.sender .msg-box {
    padding: 10px 15px;
    border-radius: 5px 5px 5px 0;
}
.sm-chat-body .msg-body ul li.repaly .msg-box {
    padding: 10px 15px;
    border-radius: 5px 5px 0 5px;
}
.sm-chat-body .msg-body ul li.sender .msg-box:before {
    left: -11px;
    width: 12px;
    height: 12px;
}
.sm-chat-body .msg-body ul li.repaly .msg-box:before {
    right: -11px;
    width: 12px;
    height: 12px;
}
.sm-chat-body .msg-body ul li {
    list-style: none;
    margin: 0 0 10px;
}
.sm-chat-body .time {
    bottom: 3px;
    right: 13px;
}
.sm-chat-header .button-wrapper {
    width: 22px;
    height: 22px;
    margin: 0;
    padding: 0;
}
.sm-chat-header .button-wrapper .img-fluid {
    max-width: 9px;
}
.sm-chat-footer {
    padding: 7px 10px;
}
.sm-chat-footer .form-control {
    height: 30px;
    font-size: 11px;
}
.group-chat-checkbox {
    top: -10px;
    position: relative;
}
.chat-edit-icon {
    background: transparent;
    border: none;
}


.chat-group-list .custom-checkbox label {
    position: relative;
    cursor: pointer;
    padding: 3px 0 0 30px;
}

.chat-group-list .custom-checkbox label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    position: absolute;
    left: 0;
    top: 2px;
}

.chat-group-list button.selected .custom-checkbox label:before {
    background: #fff;
}

.chat-group-list button.selected .custom-checkbox label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 11px;
    background: #fff;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
    transform: rotate(45deg);
}
.text-block {
    color: #333 !important;
}

.chat-border-or {
    padding-top: 40px;
    margin-bottom: 40px;
    border-bottom: 2px solid #3F404C;
    position: relative;
}
.chat-or-circle {
    height: 49px;
    width: 49px;
    background-color: #3F404C;
    border-radius: 50%;
    color: #F1ECEC;
    font-size: 19px;
    font-weight: 900;
    letter-spacing: -0.15px;
    line-height: 49px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -25px;
}
.no-chat-box {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
    background-color: #2E3134;
    max-width: 530px;
    margin: 0 auto;
}
.chat-modal-header {
    background: #31313d;
    border-color: #23242d !important;
}
.chat-modal-header .modal-title {
    color: #fff !important;
}
@media(max-width:1199px) {
    .chatlist {
        width: 330px;
    }
    .chatbox {
        width: calc(100% - 330px);
        background-color: #23242D;
    }
}
@media(max-width:991px) {
    .chatlist {
        width: 100%;
    }
    .chatbox {
        width: 100%;
    }
    .chatbox {
        position: absolute;
        right: -100%;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        z-index: 2;
    }
    .chatbox.active {
        right: 0;
    }
}
@media(max-width:767px) {
    .message-area {
        padding: 0;
    }
    .msg-body {
        padding: 20px 24px 50px;
    }
    .send-box {
        padding: 20px 20px;
    }
    .button-wrapper {
        width: 20px;
        margin: 0;
    }
    .message-area.chat-wrap .container {
        padding: 0;
    }
    .chat-header {
        padding: 10px 20px;
    }
    .chat-list button.d-flex {
        padding: 10px 15px;
    }
    .msg-head .search-icon {
        display: none;
    }
    .msg-head {
        padding: 12px 15px;
    }
    .msg-head > .d-flex:first-child {
        width: calc(100% - 50px);
    }
    .msg-body ul li.sender .msg-box,
    .msg-body ul li.repaly .msg-box {
        padding: 20px 20px 30px;
    }
    .msg-body ul li p { 
        font-size: 12px;
        letter-spacing: -0.07px;
        line-height: 18px;
    }
    .chat-area {
        height: 100vh;
    }
    .hide-for-mobile {
        display: none !important;
    }
    .msg-head .d-flex {
        display: block !important;
        text-align: center;
    } 
    .msg-head .chat-sm-title {
        margin-bottom: 3px;
    }
    .sender-name {
        font-size: 11px;
        margin-bottom: 2px;
    }
    .group-mob-header {
        height: 60px;
    }
}
@media (max-width:767px) {
    .message-area {
        height: 100%;
    }
    .chat-area {
        height: 100vh;
    } 
    .chat-dialog-scrollable .modal-body {
        height: calc(100% - 132px) !important;
    }
    .chatbox {
        height: 100%;
    }
    .chatlist {
        max-height: 100vh;
    }
    .msg-body ul li.sender .msg-box, 
    .msg-body ul li.repaly .msg-box {
        padding: 10px 12px 26px;
    }
    .no-chat-box {
        background-color: #3F404C;
        border-radius: 8px;
    }
    .no-chat-box img {
        max-width: 52px;
    }
    .msg-head .chat-sm-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 200px;
    }
    .chat-dropdown.chatmenu-dropdown {
        vertical-align: -2px;
    }
    .chat-search-icon.me-4 {
        margin-right: 1rem !important;
    }
}

@media(max-width:600px) {
    button.chat-theme-btn,
    button.chat-theme-btn {
        width: 100px;
        margin-left: 0;
    }
    .send-box .form-control {
        width: calc(100% - 165px);
    } 
    .modal-body.px-5.py-5 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
    .modal-body.px-5.py-5 .chat-custom-form {
        padding: 0 20px;
    }
}
@media(max-width:480px) {
    .send-box .form-control {
        width: calc(100% - 145px);
    }
    .msg-box img {
        max-width: 100% !important;
    }
}

.chat-header .ch-xs-title {
    cursor: pointer;
}